const Highlighted4 = () => {

    const name = 'GEMMA (Genome, Environment, Microbiome and Metabolome in Autism)';
    const projAims = 'To learn more about the many factors that contribute to the development of autism. This long-term, multicenter study follows infants who are genetically at-risk of developing ASD for their first three years of life with visits that can be completed at their local pediatrician’s office. Infants can even be enrolled before birth!';
    const leadInves = 'Dr. Alessio Fasano, MassGeneral Hospital for Children';
    const locations = 'US-wide';
    const ages = '0-6 months';
    const addCrit = 'any infant 0-6 months of of age and that has a sibling with ASD is eligible for this study';
    const contact = 'mghgemma @ mgh.harvard.edu';


    return (
        <div className={`w-full mt-4 bg-white p-6 rounded-2xl shadow-xl flex flex-row`}>

            <div className="w-full">
                <div className="mb-2 flex flex-row items-center">
                <img src={require('../Assets/4.jpeg')}  alt="test" className="h-20 mr-4 " />
                    <h3 className="text-xl font-bold text-left">{name}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Project Aims:</h3>
                    <div>
                        {projAims}
                    </div>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Lead Investigator/Institution:</h3>
                    <h3 className="text-left">{leadInves}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Location(s):</h3>
                    <h3 className="text-left">{locations}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Contacts:</h3>
                    <h3 className="text-left">{contact}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Ages:</h3>
                    <h3 className="text-left">{ages}</h3>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Additional Criteria for Inclusion:</h3>
                    <h3 className="text-left">
                        {addCrit.split(';').map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                        ))}
                    </h3>
                </div>

                {/* 
            <div className="mt-2 mb-2">
                <h3 className="font-semibold text-left">For more information please visit: <a href={Link} className="text-blue-500 hover:text-blue-700">{Link}</a></h3>
            </div> */}
            </div>
        </div>
    );
}

export default Highlighted4;


const Highlighted5 = () => {

    const name = 'Proteomics study to develop biological markers for ASD';
    const projAims = 'To develop blood-based biomarkers to help improve screening for autism risk and to identify therapeutic targets for clinical interventions. Assessments are conducted online and a blood collection kit mailed to the family that can be taken to any local blood processing center.';
    const leadInves = 'Dr. Laura Hewitson, The Johnson Center for Child Health and Development.';
    const locations = 'US-wide (excluding Alaska and Hawaii)';
    const ages = '18 months to 8 years of age.';
    const addCrit = 'Male or female children with an autism diagnosis and no severe somatic, neurological or genetic disorder.';
    const contact = 'Please complete this form to indicate interest.';


    return (
        <div className={`w-full mt-4 bg-white p-6 rounded-2xl shadow-xl flex flex-row`}>

            <div className="w-full">
                <div className="mb-2 flex flex-row items-center">
                <img src={require('../Assets/5.jpeg')} alt="test" className="h-20 mr-4 " />
                    <h3 className="text-xl font-bold text-left">{name}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Project Aims:</h3>
                    <div>
                        {projAims}
                    </div>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Lead Investigator/Institution:</h3>
                    <h3 className="text-left">{leadInves}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Location(s):</h3>
                    <h3 className="text-left">{locations}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Contacts:</h3>
                    <h3 className="text-left">{contact}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Ages:</h3>
                    <h3 className="text-left">{ages}</h3>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Additional Criteria for Inclusion:</h3>
                    <h3 className="text-left">
                        {addCrit.split(';').map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                        ))}
                    </h3>
                </div>

                {/* 
            <div className="mt-2 mb-2">
                <h3 className="font-semibold text-left">For more information please visit: <a href={Link} className="text-blue-500 hover:text-blue-700">{Link}</a></h3>
            </div> */}
            </div>
        </div>
    );
}

export default Highlighted5;


import LoginEntry from "./Components/LogInEntry";
import NavBar from "../Common/Components/Navbar";

const Login = () => {
    return (
        <div className="">
            <NavBar />
            <div className="h-screen grid grid-cols-1 justify-center bg-[#f5f4f4]">
                <LoginEntry />
            </div>
        </div>

    );
}

export default Login;
import React from 'react';
// import { Auth } from 'aws-amplify';
import { signUp } from 'aws-amplify/auth';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { createUser } from 'src/graphql/mutations';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

const CustomSignUp = () => {

  const handleCustomSignUp = async (formData) => {
    try {
      const { username, password, email, phone_number } = formData;

      const localStorageKeys = Object.keys(localStorage);
      const user = {};
  
      localStorageKeys.forEach(key => {
        user[key] = localStorage.getItem(key);
      });
  
      await client.graphql({
        query: createUser,
        variables: {
          input: user
        }
      });

      // Customize signup logic here (e.g., perform additional checks, modify data, etc.)
      // Your custom signup logic before calling Auth.signUp

      const signUpResponse = await signUp({
        username,
        password,
        attributes: {
          email,
          phone_number,
        },
      });
    } catch (error) {
      console.error('Error during custom signup:', error);
    }
  };

  return <signUp signUpConfig={{ signUpFields: [{ label: 'CustomSignUp', key: 'customSignUp', required: true }] }} handleSignUp={handleCustomSignUp} />;
};


const YourComponent = ({ signOut, user }) => {
  return (
    <div>
      <h1>Hello {user.username}</h1>
      <button onClick={signOut}>Sign out</button>
    </div>
  );
};

export default withAuthenticator(YourComponent, { signUpConfig: {} }, [CustomSignUp]);

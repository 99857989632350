import { Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from 'src/UserContext';
import Home from './Common/Home';
import Login from './Login/Login.js';
import Display from './UserProfile/Display.js';
import AvailTrials from './AvailTrials/AvailTrials';
import ValidatePage from './ValidateLogin/ValidatePage';
import Search from './Search/Search';
import SignUp from './RegistryForm/SignUp';
import ForgotPassword from './RegistryForm/ForgotPassword';
import GenerateCode from './RegistryForm/GenerateCode';

import Form from './Synchrony/Form';


function App() {
  const { isSignedIn, setIsSignedIn } = useUser();

  return (
    <div className="App">
      <Routes>
        <Route
          path='/'
          element={isSignedIn ? <Display /> : <Home />}
        />
        <Route
          path="/profile"
          element={isSignedIn ? <Display /> : <Login />}
        />
        <Route
          path="/searchresults/*"
          element={<AvailTrials />}
        />
        <Route
          path="/explore"
          element={<Search />}
        />
        <Route path='/signin' element={<Login />} />
        <Route path='/validatePage' element={<ValidatePage />} />

        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/generateCode' element={<GenerateCode />} />

        <Route path='/signout' element={<SignUp />} />
        <Route path='/case_studies/submit' element={<Form />} />

      </Routes>
    </div>
  );
}

function AppWithProvider() {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
}

export default AppWithProvider;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut, getCurrentUser, signIn } from 'aws-amplify/auth';
import { useUser } from 'src/UserContext';


const NavBar = ({ handleTabChange }) => {
  const navigate = useNavigate();

  const { isSignedIn, setIsSignedIn } = useUser();

  const handleExploreClick = () => {
    navigate('/');
    handleTabChange('trials');
  };

  const handleSignupClick = () => {
    navigate('/');
    handleTabChange('form');
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
      setIsSignedIn(false)
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };


  // const checkSignedIn = () => {
  //   const storedValue = localStorage.getItem('CognitoIdentityServiceProvider.761nnq6f3f215v51rh0p2vnvik.b02d4c90-45fa-4124-bf35-b640c6964937.idToken');
  //   if (storedValue) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   checkSignedIn();
  // }, []); 


  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    console.log("clickled")
    setDropdownVisible(!isDropdownVisible);
  };


  return (
    // <nav className="bg-gradient-to-r from-[#948aa1] to-[#2b2138] to-80% py-2 px-4 flex justify-between items-center relative">
    <nav className="bg-gradient-to-r from-white to-[#2b2138] to-95% py-2 px-4 flex justify-between items-center relative">
      <Link to="/">
        <img src={require('../Assets/BrainFoundationHD.png')} alt="Logo" className="px-2 py-2" />
      </Link>
  
      <div className="space-x-4 hidden md:block">
        <Link to="/profile">
          <button className="px-1 md:px-2 font-medium text-base text-white hover:text-gray-400">
            {isSignedIn ? "Profile" : "Home"}
          </button>
        </Link>
  
        <Link to="/explore">
          <button className="px-1 md:px-2 font-medium text-base text-white hover:text-gray-400">
            Explore Trials
          </button>
        </Link>
  
        {isSignedIn ? (
          <button onClick={handleSignOut} className="px-1 md:px-2 font-medium text-base text-white hover:text-gray-400">
            Sign Out
          </button>
        ) : (
          <Link to="/signin">
            <button className="px-1 md:px-2 font-medium text-base text-white hover:text-gray-400">
              Login
            </button>
          </Link>
        )}
      </div>
  
      <div className="relative md:hidden">
        <button className="" onClick={toggleDropdown}>
          <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33983 5.96948C4.99205 5.96948 4.71008 6.25145 4.71008 6.59923C4.71008 6.94702 4.99205 7.22898 5.33983 7.22898H14.6603C15.0081 7.22898 15.2901 6.94702 15.2901 6.59923C15.2901 6.25145 15.0081 5.96948 14.6603 5.96948H5.33983ZM5.33983 9.37015C4.99205 9.37015 4.71008 9.65212 4.71008 9.9999C4.71008 10.3477 4.99205 10.6296 5.33983 10.6296H14.6603C15.0081 10.6296 15.2901 10.3477 15.2901 9.9999C15.2901 9.65205 15.0081 9.37015 14.6603 9.37015H5.33983ZM5.33983 12.7708C4.99205 12.7708 4.71008 13.0528 4.71008 13.4006C4.71008 13.7483 4.99205 14.0303 5.33983 14.0303H14.6603C15.0081 14.0303 15.2901 13.7483 15.2901 13.4006C15.2901 13.0528 15.0081 12.7708 14.6603 12.7708H5.33983Z" fill="white" />
          </svg>
        </button>
  
        <ul className={`${isDropdownVisible ? 'block' : 'hidden'} w-40 absolute -left-24 mt-2 py-2 px-4 bg-[#2b2138] text-white rounded-md shadow-lg z-10`}>
          <li>
            <Link to="/profile">
              <button className="px-1 font-medium text-md text-white hover:text-gray-400">
                {isSignedIn ? "Profile" : "Home"}
              </button>
            </Link>
          </li>
  
          <li>
            <Link to="/explore">
              <button className="px-1 font-medium text-md text-white hover:text-gray-400">
                Explore Trials
              </button>
            </Link>
          </li>
  
          {isSignedIn ? (
            <li>
              <button onClick={handleSignOut} className="px-1 md:px-2 font-medium text-md text-white hover:text-gray-400">
                Sign Out
              </button>
            </li>
          ) : (
            <li>
              <Link to="/signin">
                <button className="px-1 md:px-2 font-medium text-base text-white hover:text-gray-400">
                  Login
                </button>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { searchClinicalTrialsLists } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import amplifyconfig from '../amplifyconfiguration.json';
import Trial from './Components/Trial';
import NavBar from 'src/Common/Components/Navbar';
import { useLocation } from 'react-router-dom';

Amplify.configure(amplifyconfig);
const client = generateClient();

const AvailTrials = () => {

  const [trials, setTrials] = useState([]);
  const [filteredTrials, setFilteredTrials] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const keywordParam = queryParams.get('keyword').toLowerCase();
  const ageParam = queryParams.get('Age').toLowerCase();
  const genderParam = queryParams.get('gender').toLowerCase();
  const locationParam = queryParams.get('location');
  //const distanceParam = queryParams.get('distance');
  const statusParam = queryParams.get('status').toLowerCase();
  const [keywordFilter, setKeywordFilter] = useState(keywordParam || '');
  const [ageFilter, setAgeFilter] = useState(ageParam || '');
  const [genderFilter, setGenderFilter] = useState(genderParam || '');
  const [locationFilter, setlocationFilter] = useState(locationParam || '');
  //const [distanceFilter, setDistanceFilter] = useState(distanceParam || '');
  const [statusFilter, setStatusFilter] = useState(statusParam || '');
  //const [loadingDistances, setLoadingDistances] = useState(true);
  let mySet = new Set();

  // If ageFilter, statusFilter, genderFilter updates, fetchData again
  useEffect(() => {
    fetchData();
  }, [ageFilter, statusFilter, genderFilter]);


  // Function to convert age
  const parseAgeString = (ageString) => {
    const ageParts = ageString.split(' '); // Split the age string into parts
    let numericValue = parseInt(ageParts[0]); // Extract the numeric value
    if (ageParts[1] === 'months') {
      numericValue /= 12; // Convert months to years
    } else if (ageParts[1] === 'days') {
      numericValue /= 365; // Convert days to years
    }
    return numericValue;
  };


  useEffect(() => {

    filterByLocation()
  }, [filteredTrials]);

  const fetchData = async () => {
    try {
      // Takes care of age, status, gender, keyword
      const filter = {
        ...(ageFilter && {
          maxAgeFloat: { gte: parseAgeString(ageFilter) }, //do age filter later
          minAgeFloat: { lte: parseAgeString(ageFilter) }

        }),
        ...(statusFilter === 'rnr' && {
          status: { match: 'RECRUITING' },
        }),
        ...(genderFilter === 'male' && {
          sex: { match: 'Male' }
        }),
        ...(genderFilter === 'female' && {
          sex: { match: 'Female' }
        }),
        ...(keywordFilter && {
          and: keywordFilter.split(' ').map(word => ({
            or: [
              { officialTitle: { match: word } },
              { briefSummary: { match: word } },
              { nctId: { match: word } },
            ]
          }))
        }),
      };


      const trialsData = await client.graphql({
        query: searchClinicalTrialsLists,
        variables: {
          filter: filter,
          limit: 1000,
          nextToken: null
        },
      });

      setFilteredTrials(trialsData.data.searchClinicalTrialsLists.items)

    } catch (error) {
      console.error('Error fetching distances for trial:', error.message);
      return null;
    }
  }


  const filterByLocation = async () => {

    if (filteredTrials.length > 0) {
      if (locationFilter !== "") {
        //console.log("Alert:", locationFilter);
        const trialsWithLocation = filteredTrials.filter(trial => {
          if (trial.locationArray && trial.locationArray.length > 0) {
            return trial.locationArray.some(location => {
              return location.country === locationFilter;
            });
          }
          return false;
        });
        setTrials(trialsWithLocation);
      } else {
        setTrials(filteredTrials);
      }
    }
  };



  const [currentPage, setCurrentPage] = useState(1);
  const trialsPerPage = 20;
  const indexOfLastTrial = currentPage * trialsPerPage;
  const indexOfFirstTrial = indexOfLastTrial - trialsPerPage;
  const currentTrials = trials.slice(indexOfFirstTrial, indexOfLastTrial);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(trials.length / trialsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <div className="w-full">
      <NavBar />
      <div className="mb-4">
        <div className="w-4/5 mx-auto m-4 mb-4">
          <h3 className="text-2xl font-bold text-left">Search Results:</h3>
          <h3 className="">
            Now viewing {indexOfFirstTrial + 1}-{Math.min(indexOfLastTrial, trials.length)} out of {trials.length} trials
          </h3>
          <br />
          <h3 className="">
            Please note that the studies listed on this page are not run or organized by the BRAIN Foundation, and <strong>we are unable to reply to email inquiries about clinical trials.</strong> We attempt to give as much detail as possible below, but if you have any further questions, please contact the study organizers directly.
          </h3>
        </div>
      </div>
      <div className="flex flex-col items-center mb-10">
        {trials.length > 0 ? (trials.map((trial, index) => (
          <Trial
            key={index}
            Identifier={trial.nctId}
            Name={trial.officialTitle}
            Status={trial.status === 'NOT_YET_RECRUITING' ? 'NOT YET RECRUITING' : trial.status}
            projAims={trial.briefSummary}
            leadInves={trial.orgName}
            Ages={
              trial.minAge !== null && trial.maxAge !== null
                ? `${trial.minAge} old - ${trial.maxAge} old`
                : trial.minAge !== null
                  ? `${trial.minAge} old or above`
                  : trial.maxAge !== null
                    ? `${trial.maxAge} old or below`
                    : 'No age restrictions'
            }
            addCrit={trial.eligibilityCriteria}
            Contacts={trial.centralContacts || []}
            Location={trial.locationArray || []}
            Link={trial.studyLink}
            distanceInfo={trial.locationDistances}
          />
        ))) : (<p>No trials available</p>)}
      </div>
      <div className="w-full overflow-x-autoflex justify-center mb-4">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`mx-2 p-2 border ${currentPage === number ? 'font-bold text-lg' : ''}`}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
}

export default AvailTrials;
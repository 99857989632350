import React, { useState, useEffect } from 'react';
import { getUser, getUserByEmail } from 'src/graphql/queries'; // Import getUserByEmail if it's from an external module
import { updateUser } from 'src/graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from "aws-amplify/auth"
import { fetchUserAttributes, updateUserAttributes } from "aws-amplify/auth"

const client = generateClient()

const EditInfo = () => {

  const [userDetails, setUserDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({});
  const [numTabs, setNumTabs] = useState(1);

  async function fetchUserSubFromCognito() {
    try {
      const user = await fetchUserAttributes();
      const userData = await client.graphql({
        query: getUser,
        variables: {
          id: user.sub
        }
      });

      setUserDetails(userData.data.getUser)
      console.log("here", userDetails)
      return;

    } catch (error) {
      console.log('Error fetching user sub from auth:', error);

      return null;
    }
  }

  useEffect(() => {
    fetchUserSubFromCognito();
  }, []);

  async function editUserInfo(requests) {
    try {
      const user = await getCurrentUser();
      const userId = user.userId;
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: userId,
            ...requests
          },
        },
      });
      return;
    } catch (error) {
      console.log("There was an error updating user information from DB: ", error);
    }
  }

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,

    }));
  };

  const handleEditClick = () => {
    console.log(userDetails)
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    try {
      await editUserInfo(userData);
      // console.log('Updated User requests:', userData);

    } catch (error) {
      console.error('Error updating user information:', error);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);

    setUserData({
      firstName: userDetails["firstName"] || '',
      middleName: userDetails["middleName"] || '',
      lastName: userDetails["lastName"] || '',
      dob: userDetails["dob"] || '',
      email: userDetails["email"] || '',
      phoneNumber: userDetails["phoneNumber"] || '',
      streetAddress1: userDetails["streetAddress1"] || '',
      streetAddress2: userDetails["streetAddress2"] || '',
      city: userDetails["city"] || '',
      state: userDetails["state"] || '',
      postalCode: userDetails["postalCode"] || '',
      country: userDetails["country"] || '',
    });

  };


  useEffect(() => {
    if (userDetails) {
      setUserData({
        firstName: userDetails["firstName"] || '',
        middleName: userDetails["middleName"] || '',
        lastName: userDetails["lastName"] || '',
        dob: userDetails["dob"] || '',
        email: userDetails["email"] || '',
        phoneNumber: userDetails["phoneNumber"] || '',
        streetAddress1: userDetails["streetAddress1"] || '',
        streetAddress2: userDetails["streetAddress2"] || '',
        city: userDetails["city"] || '',
        state: userDetails["state"] || '',
        postalCode: userDetails["postalCode"] || '',
        country: userDetails["country"] || '',
      });
    }
  }, [userDetails]);

  const tabNumbers = Array.from({ length: numTabs }, (_, index) => index + 1);
  // const numTabs = userDetails.patientIDList.length;

  return (
    <div className="w-full min-h-screen overflow-y-auto ">
      {/* <div className="flex flex-wrap w-full justify-center pb-4">
        <div className="w-4/5 flex flex-row justify-between items-center pt-12">
          <h3 className="text-3xl font-bold text-left">Patient Info</h3>
          {isEditing ? (
            <div className="flex">
              <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 rounded-2xl" onClick={handleSaveClick}>
                Save
              </button>
              <button className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded-2xl" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          ) : (
            <button className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-2xl" onClick={handleEditClick}>
              Edit
            </button>
          )}
        </div>
      </div> */}

      <div className="w-4/5 mx-auto flex flex-col">

        <div className="flex flex-col">
          {Object.keys(userData).map((key) => (
            <div key={key} className="mb-4">
              <label className="w-full text-left font-medium text-base text-gray-800 mb-2">
                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
              </label>
              <input
                type={key === 'dob' ? 'date' : key === 'procedureCodes' || key === 'interests' ? 'textarea' : 'text'}
                name={key}
                className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                  }`}
                value={userData[key]}
                onChange={handleUserDataChange}
                readOnly={!isEditing}
              />
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};


export default EditInfo;
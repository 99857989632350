import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import S3 from 'aws-sdk/clients/s3'; // Import only the S3 client
import AWS from 'aws-sdk';

const Form = () => {
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);


    const initialState = {
        primaryQuestion: '',
        additionalInformation: '',
        educationOther: '',
        independenceOther: '',
        socialOther: '',
        checkboxes: {
            sleep: [],
            language: [],
            geneticDisorder: [],
            syndromic: [],
            neurologic: [],
            movementDisorder: [],
            toneAbnormalities: [],
            gastrointestinalDisorder: [],
            metabolicDisorder: [],
            nutritionalDisorder: [],
            immuneDisorder: [],
            attentionDisorder: [],
            anxietyDisorder: [],
            aggressiveBehavior: [],
            selfInjuringBehavior: [],
            sensoryModulationDisorder: [],
            sleepDisorder: [],
            growthDisorder: [],
            coordination: []
        },
        medications: [{ id: 0, medication: '', didItWork: '', details: '' }],
        labs: [{ id: 0, labTest: '', result: '', details: '' }],
        currentMedications: [{ id: 0, medication: '', didItWork: '', details: '' }],
        currentLabs: [{ id: 0, labTest: '', result: '', details: '' }],
        age: '',
        gender: '',
        conditions: {
            relapsing: { value: '', details: '' },
            seizures: { value: '', details: '' },
            aggressive: { value: '', details: '' },
            gastroIntestinal: { value: '', details: '' },
            immunological: { value: '', details: '' },
            mitochondrial: { value: '', details: '' }
        },
        closing_remarks: '',
    };

    const [formState, setFormState] = useState(initialState);


    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        setFormState(prevState => {
            const currentGroup = prevState.checkboxes[name] || [];
            const updatedGroup = checked
                ? [...currentGroup, value]
                : currentGroup.filter(item => item !== value);
            return { ...prevState, checkboxes: { ...prevState.checkboxes, [name]: updatedGroup } };
        });
    };

    const handleConditionChange = (condition, field, value) => {
        setFormState(prevState => ({
            ...prevState,
            conditions: {
                ...prevState.conditions,
                [condition]: { ...prevState.conditions[condition], [field]: value }
            }
        }));
    };

    const handleMedicationChange = (index, field, value) => {
        const newMedications = [...formState.medications];
        newMedications[index][field] = value;
        setFormState({ ...formState, medications: newMedications });
    };

    const handleLabChange = (index, field, value) => {
        const newLabs = [...formState.labs];
        newLabs[index][field] = value;
        setFormState({ ...formState, labs: newLabs });
    };

    const addMedication = () => {
        setFormState(prevState => ({
            ...prevState,
            medications: [...prevState.medications, { id: prevState.medications.length, medication: '', didItWork: '', details: '' }]
        }));
    };

    const deleteMedication = (id) => {
        setFormState(prevState => ({
            ...prevState,
            medications: prevState.medications.filter(med => med.id !== id)
        }));
    };

    const addLab = () => {
        setFormState(prevState => ({
            ...prevState,
            labs: [...prevState.labs, { id: prevState.labs.length, labTest: '', result: '', details: '' }]
        }));
    };

    const deleteLab = (id) => {
        setFormState(prevState => ({
            ...prevState,
            labs: prevState.labs.filter(lab => lab.id !== id)
        }));
    };

    const handleCurrentMedicationChange = (index, field, value) => {
        const newMedications = [...formState.currentMedications];
        newMedications[index][field] = value;
        setFormState({ ...formState, currentMedications: newMedications });
    };

    const handleCurrentLabChange = (index, field, value) => {
        const newLabs = [...formState.currentLabs];
        newLabs[index][field] = value;
        setFormState({ ...formState, currentLabs: newLabs });
    };

    const addCurrentMedication = () => {
        setFormState(prevState => ({
            ...prevState,
            currentMedications: [...prevState.currentMedications, { id: prevState.currentMedications.length, medication: '', didItWork: '', details: '' }]
        }));
    };

    const deleteCurrentMedication = (id) => {
        setFormState(prevState => ({
            ...prevState,
            currentMedications: prevState.currentMedications.filter(med => med.id !== id)
        }));
    };

    const addCurrentLab = () => {
        setFormState(prevState => ({
            ...prevState,
            currentLabs: [...prevState.currentLabs, { id: prevState.currentLabs.length, labTest: '', result: '', details: '' }]
        }));
    };

    const deleteCurrentLab = (id) => {
        setFormState(prevState => ({
            ...prevState,
            currentLabs: prevState.currentLabs.filter(lab => lab.id !== id)
        }));
    };

    const lastDivRef = useRef(null);
    useEffect(() => {
        if (formState.medications.length > 1 && lastDivRef.current) {
            lastDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [formState.medications]);

    const lastLabRef = useRef(null);
    useEffect(() => {
        if (formState.labs.length > 1 && lastLabRef.current) {
            lastLabRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [formState.labs]);

    const lastCurrentDivRef = useRef(null);
    useEffect(() => {
        if (formState.currentMedications.length > 1 && lastCurrentDivRef.current) {
            lastCurrentDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [formState.currentMedications]);

    const lastCurrentLabRef = useRef(null);
    useEffect(() => {
        if (formState.currentLabs.length > 1 && lastCurrentLabRef.current) {
            lastCurrentLabRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [formState.currentLabs]);

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     console.log(formState);
    // };
    const uploadFile = async () => {
        const S3_BUCKET = 'registry-casestudies';
        const REGION = 'us-east-1';

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: REGION,
        });

        const s3 = new AWS.S3();

        const file = new File([JSON.stringify(formState)], `form-data-${Date.now()}.json`, {
            type: 'application/json',
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: file.name,
            Body: file,
            ContentType: file.type,
        };


        try {
            const upload = s3.putObject(params).promise();
            await upload;
            setIsModalVisible(true); // Show modal on success
        } catch (error) {
            console.error('Error uploading file:', error);
            alert("Error saving your information. Please try again later.");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const s3 = new S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_REGION
        });

        const params = {
            Bucket: 'registry-casestudies',
            Key: `form-data-${Date.now()}.json`,
            Body: JSON.stringify(formState),
            ContentType: 'application/json'
        };

        try {
            await s3.putObject(params).promise();
            console.log('Form data uploaded successfully');
        } catch (error) {
            console.error('Error uploading form data:', error);
        }
    };

    return (
        <div className="w-full flex flex-col mx-4 xs:mx-8 md:px-12 py-12">
            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col">
                <h3 className="w-full text-4xl text-center text-[#005077] font-semibold">Synchrony 2024</h3>
                <h3 className="w-full text-black text-3xl text-center font-semibold mt-4">Unraveling Complex Cases in ASD: Case Report Form</h3>
                <h3 className="w-full text-black text-xl text-left mt-4 ">A panel of specialists from neurology, immunology, gastroenterology and other fields will be reviewing complex cases of autism with medical comorbidities. If you would like to submit a case report to be considered, please fill out this form. Please do not include any personal identifiyng information in your form.</h3>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col mt-4 ">
                <div className="w-full">
                    <label htmlFor="primaryQuestion" className="w-full text-left font-medium text-base text-gray-800 text-xl font-semibold">In one sentence, please state your primary question for this panel. You will have a chance to describe more in detail later.</label>
                    <textarea
                        type="text"
                        id="primaryQuestion"
                        name="primaryQuestion"
                        className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="Please enter your primary question here"
                        required
                        value={formState.primaryQuestion}
                        onChange={(e) => setFormState({ ...formState, primaryQuestion: e.target.value })}
                    />
                </div>
            </div>


            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col mt-4 ">
                <h3 className="text-xl font-semibold">Patient Profile</h3>
                <h3 className="text-lg ">Please provide some basic information about the patient</h3>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col mt-2 ">
                <div className="flex flex-col md:flex-row justify-between w-full gap-2 ">
                    <div className="w-full md:w-1/2 mr-2 mb-4 md:mb-0">
                        <label htmlFor="age" className="w-full text-left font-medium text-base text-gray-800 mb-2">Age</label>
                        <input
                            type="number"
                            id="age"
                            name="age"
                            className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                            placeholder="Age"
                            required
                            value={formState.age}
                            onChange={(e) => setFormState({ ...formState, age: e.target.value })}
                        />
                    </div>
                    <div className="w-full md:w-1/2">
                        <label htmlFor="gender" className="w-full text-left font-medium text-base text-gray-800 mb-2">Gender Identity</label>
                        <input
                            type="text"
                            id="gender"
                            name="gender"
                            className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]" placeholder="Your gender"
                            required
                            value={formState.gender}
                            onChange={(e) => setFormState({ ...formState, gender: e.target.value })}
                        />
                    </div>
                </div>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col gap-x-8 mt-4 ">
                <div className="flex flex-col mt-2 ">
                    <h3 className="text-left font-semibold  text-base text-gray-800 mb-2  ">Independence</h3>
                    <div className="grid grid-col-2 gap-2">
                        <label className="flex items-center">
                            <input type="checkbox" name="independence" value="constant_supervision" className="mr-2" onChange={handleCheckboxChange} />
                            Requires constant supervision
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="independence" value="short_periods" className="mr-2" onChange={handleCheckboxChange} />
                            Can be left alone for short periods
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="independence" value="periodic_checkin" className="mr-2" onChange={handleCheckboxChange} />
                            Independent with periodic check-in
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="independence" value="fully_independent" className="mr-2" onChange={handleCheckboxChange} />
                            Fully Independent
                        </label>
                        <label className="col-span-2 flex items-center">
                            <input type="checkbox" name="independence" value="other" className="mr-2" onChange={handleCheckboxChange} />
                            Other
                        </label>
                        <textarea
                            type="text"
                            name="independence"
                            className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                            placeholder="If other is selected, please specify"
                            // disabled={!formState.checkboxes.independence.includes('other')}
                            maxLength="400"
                            value={formState.independenceOther} // Add this line
                            onChange={(e) => setFormState({ ...formState, independenceOther: e.target.value })} // Add this line
                        />
                    </div>
                </div>
                <div className=" flex flex-col mt-4 ">
                    <h3 className="text-left font-semibold  text-base text-gray-800  mb-2 ">Social Cognition</h3>
                    <div className="grid grid-col-2 gap-2">
                        <label className="flex items-center">
                            <input type="checkbox" name="social_cognition" value="relates_close_family" className="mr-2" onChange={handleCheckboxChange} />
                            Relates to close family
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="social_cognition" value="relates_extended_family" className="mr-2" onChange={handleCheckboxChange} />
                            Relates to extended family
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="social_cognition" value="peer_buddies" className="mr-2" onChange={handleCheckboxChange} />
                            Has peer buddies
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="social_cognition" value="has_friends" className="mr-2" onChange={handleCheckboxChange} />
                            Has friends
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="social_cognition" value="independent_engagement" className="mr-2" onChange={handleCheckboxChange} />
                            Independently engages with friends
                        </label>
                        <label className="col-span-2 flex items-center">
                            <input type="checkbox" name="social_cognition" value="other" className="mr-2" onChange={handleCheckboxChange} />
                            Other
                        </label>
                        <textarea
                            type="text"
                            name="social_cognition"
                            className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                            placeholder="If other is selected, please specify"
                            // disabled={!formState.checkboxes.social_cognition.includes('other')}
                            maxLength="400"
                            value={formState.socialOther} // Add this line
                            onChange={(e) => setFormState({ ...formState, socialOther: e.target.value })} // Add this line
                        />
                    </div>
                </div>

                <div className=" flex flex-col pt-4 ">
                    <h3 className="text-left font-semibold  text-base text-gray-800  mb-2 ">Education</h3>
                    <div className="grid grid-col-2 gap-2">
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="homeschooled" className="mr-2" onChange={handleCheckboxChange} />
                            Homeschooled
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="attends_with_aide" className="mr-2" onChange={handleCheckboxChange} />
                            Attends with aide
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="attends_independently" className="mr-2" onChange={handleCheckboxChange} />
                            Attends independently
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="mainstream_school" className="mr-2" onChange={handleCheckboxChange} />
                            Mainstream school
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="special_education" className="mr-2" onChange={handleCheckboxChange} />
                            Special Education
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="mixed_classes" className="mr-2" onChange={handleCheckboxChange} />
                            Mixed classes (mainstream and special education)
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="high_school_diploma_track" className="mr-2" onChange={handleCheckboxChange} />
                            High school Diploma Track
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="in_college" className="mr-2" onChange={handleCheckboxChange} />
                            In College
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="at_work" className="mr-2" onChange={handleCheckboxChange} />
                            At work
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="education" value="grad" className="mr-2" onChange={handleCheckboxChange} />
                            Graduated college / post graduate
                        </label>
                        <label className="col-span-2 flex items-center">
                            <input type="checkbox" name="education" value="other" className="mr-2" onChange={handleCheckboxChange} />
                            Other
                        </label>
                        <textarea
                            type="text"
                            name="education"
                            className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                            placeholder="If other is selected, please specify"
                            // disabled={!formState.checkboxes.education.includes('other')}
                            maxLength="400"
                            value={formState.educationOther} // Add this line
                            onChange={(e) => setFormState({ ...formState, educationOther: e.target.value })} // Add this line
                        />
                    </div>
                </div>

            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col mt-2 ">
                <div className="w-full">
                    <label htmlFor="additionalInformation" className="w-full text-left font-medium text-base text-gray-800 mb-2">Please use this space to describe anything else about the individiual.</label>
                    <textarea
                        type="text"
                        id="additionalInformation"
                        name="additionalInformation"
                        className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="Please enter additional details here"
                        value={formState.additionalInformation}
                        onChange={(e) => setFormState({ ...formState, additionalInformation: e.target.value })}
                    />
                </div>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col mt-8 ">
                <h3 className="text-xl font-semibold">Medical History</h3>
                <h3 className="text-lg ">Please give us a description of any previous medical history. In this section, please do not list any current medication. Please also indicate if the medication worked and details of the outcome. For past labs, please list any significant / abnormal labs and any details notable details regarding them.
                    Click on the + button to add a new medication or lab.</h3>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                <div className="flex flex-row items-center justify-between">
                    <h4 className="text-left font-medium text-lg text-gray-800">Past Medications</h4>
                    <button
                        type="button"
                        onClick={addMedication}
                        className="min-w-10 min-h-10 max-w-10 max-h-10 bg-[#005077] text-white px-4 py-2 rounded"
                    >
                        +
                    </button>
                </div>
                {formState.medications.map((med, index) => (
                    <div
                        key={med.id}
                        className="grid grid-cols-10 gap-4 mt-4 relative"
                        ref={index === formState.medications.length - 1 ? lastDivRef : null}
                    >
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Medication"
                            value={med.medication}
                            onChange={(e) => handleMedicationChange(index, 'medication', e.target.value)}
                        />
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Did it work?"
                            value={med.didItWork}
                            onChange={(e) => handleMedicationChange(index, 'didItWork', e.target.value)}
                        />
                        <div className="flex flex-row col-span-6 gap-4">
                            <textarea
                                type="text"
                                className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-5"
                                placeholder="Please share details regarding why this was prescribed and outcome"
                                value={med.details}
                                onChange={(e) => handleMedicationChange(index, 'details', e.target.value)}
                                maxLength="400"
                            />

                            <button
                                type="button"
                                onClick={() => deleteMedication(med.id)}
                                className="min-w-10 min-h-10 max-w-10 max-h-10 bg-red-500 text-white px-4 py-2 rounded flex items-center justify-center"
                            >
                                x
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4">
                <div className="flex flex-row items-center justify-between">
                    <h4 className="text-left font-medium text-lg text-gray-800">Past Labs</h4>
                    <button
                        type="button"
                        onClick={addLab}
                        className="min-w-10 min-h-10 max-w-10 max-h-10 bg-[#005077] text-white px-4 py-2 rounded"
                    >
                        +
                    </button>
                </div>
                {formState.labs.map((lab, index) => (
                    <div
                        key={lab.id}
                        className="grid grid-cols-10 gap-4 mt-4 relative"
                        ref={index === formState.labs.length - 1 ? lastLabRef : null}
                    >
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Lab Test"
                            value={lab.labTest}
                            onChange={(e) => handleLabChange(index, 'labTest', e.target.value)}
                        />
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Result (High/Normal/Low)"
                            value={lab.result}
                            onChange={(e) => handleLabChange(index, 'result', e.target.value)}
                        />
                        <div className="flex flex-row col-span-6 gap-4">
                            <textarea
                                type="text"
                                className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-5"
                                placeholder="Please share details regarding this test"
                                value={lab.details}
                                onChange={(e) => handleLabChange(index, 'details', e.target.value)}
                                maxLength="400"
                            />

                            <button
                                type="button"
                                onClick={() => deleteLab(lab.id)}
                                className="min-w-10 min-h-10 max-w-10 max-h-10 bg-red-500 text-white px-4 py-2 rounded flex items-center justify-center"
                            >
                                x
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                <div className="flex flex-row items-center justify-between">
                    <h4 className="text-left font-medium text-lg text-gray-800">Current Medications</h4>
                    <button
                        type="button"
                        onClick={addCurrentMedication}
                        className="min-w-10 min-h-10 max-w-10 max-h-10 bg-[#005077] text-white px-4 py-2 rounded"
                    >
                        +
                    </button>
                </div>
                {formState.currentMedications.map((med, index) => (
                    <div
                        key={med.id}
                        className="grid grid-cols-10 gap-4 mt-4 relative"
                        ref={index === formState.currentMedications.length - 1 ? lastCurrentDivRef : null}
                    >
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Medication"
                            value={med.medication}
                            onChange={(e) => handleCurrentMedicationChange(index, 'medication', e.target.value)}
                        />
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Did it work?"
                            value={med.didItWork}
                            onChange={(e) => handleCurrentMedicationChange(index, 'didItWork', e.target.value)}
                        />
                        <div className="flex flex-row col-span-6 gap-4">
                            <textarea
                                type="text"
                                className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-5"
                                placeholder="Please share details regarding why this was prescribed and outcome"
                                value={med.details}
                                onChange={(e) => handleCurrentMedicationChange(index, 'details', e.target.value)}
                                maxLength="400"
                            />

                            <button
                                type="button"
                                onClick={() => deleteCurrentMedication(med.id)}
                                className="min-w-10 min-h-10 max-w-10 max-h-10 bg-red-500 text-white px-4 py-2 rounded flex items-center justify-center"
                            >
                                x
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4">
                <div className="flex flex-row items-center justify-between">
                    <h4 className="text-left font-medium text-lg text-gray-800">Current Labs</h4>
                    <button
                        type="button"
                        onClick={addCurrentLab}
                        className="min-w-10 min-h-10 max-w-10 max-h-10 bg-[#005077] text-white px-4 py-2 rounded"
                    >
                        +
                    </button>
                </div>
                {formState.currentLabs.map((lab, index) => (
                    <div
                        key={lab.id}
                        className="grid grid-cols-10 gap-4 mt-4 relative"
                        ref={index === formState.currentLabs.length - 1 ? lastCurrentLabRef : null}
                    >
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Lab Test"
                            value={lab.labTest}
                            onChange={(e) => handleCurrentLabChange(index, 'labTest', e.target.value)}
                        />
                        <input
                            type="text"
                            className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-2"
                            placeholder="Result (High/Normal/Low)"
                            value={lab.result}
                            onChange={(e) => handleCurrentLabChange(index, 'result', e.target.value)}
                        />
                        <div className="flex flex-row col-span-6 gap-4">
                            <textarea
                                type="text"
                                className="h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] col-span-5"
                                placeholder="Please share details regarding this test"
                                value={lab.details}
                                onChange={(e) => handleCurrentLabChange(index, 'details', e.target.value)}
                                maxLength="400"
                            />

                            <button
                                type="button"
                                onClick={() => deleteCurrentLab(lab.id)}
                                className="min-w-10 min-h-10 max-w-10 max-h-10 bg-red-500 text-white px-4 py-2 rounded flex items-center justify-center"
                            >
                                x
                            </button>
                        </div>
                    </div>
                ))}
            </div>


            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col mt-8 ">
                <h3 className="text-left font-semibold  text-base text-gray-800 mb-2  ">Relapsing / Remitting</h3>
                <h3 className="text-lg ">Do your symptoms occur in a pattern of regressions and improvements?</h3>

                <div className="flex flex-col ">
                    <label className="flex items-center">
                        <input type="radio" name="relapsing" value="yes" className="mr-2" onChange={(e) => handleConditionChange('relapsing', 'value', e.target.value)} />
                        Yes
                    </label>
                    <label className="flex items-center">
                        <input type="radio" name="relapsing" value="no" className="mr-2" onChange={(e) => handleConditionChange('relapsing', 'value', e.target.value)} />
                        No
                    </label>
                    <textarea
                        type="text"
                        name="relapsing_details"
                        className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="If yes, please describe how often regressions happen"
                        disabled={formState.conditions.relapsing.value !== 'yes'}
                        value={formState.conditions.relapsing.details}
                        onChange={(e) => handleConditionChange('relapsing', 'details', e.target.value)}
                        maxLength="400"
                    />
                </div>
            </div>


            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                <h3 className="text-left font-medium text-lg text-gray-800">Current Medical Comorbidities</h3>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto grid grid-cols-2 gap-x-8 pt-4">
                <div className=" flex flex-col">
                    <h3 className="text-left font-semibold  text-base text-gray-800  mb-2 ">Seizures</h3>
                    <label className="flex items-center">
                        <input type="radio" name="seizures" value="yes" className="mr-2" onChange={(e) => handleConditionChange('seizures', 'value', e.target.value)} />
                        Yes
                    </label>
                    <label className="flex items-center">
                        <input type="radio" name="seizures" value="no" className="mr-2" onChange={(e) => handleConditionChange('seizures', 'value', e.target.value)} />
                        No
                    </label>

                    <textarea
                        type="text"
                        name="seizures_details"
                        className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="If yes, please describe the frequency and nature of the seizures, as well as any previous drugs tried, current drugs, and outcomes"
                        disabled={formState.conditions.seizures.value !== 'yes'}
                        value={formState.conditions.seizures.details}
                        onChange={(e) => handleConditionChange('seizures', 'details', e.target.value)}
                        maxLength="400"
                    />


                </div>

                <div className=" flex flex-col pt-4 ">
                    <h3 className="text-left font-semibold  text-base text-gray-800  mb-2 ">Aggressive Behaviors</h3>
                    <label className="flex items-center">
                        <input type="radio" name="aggressive" value="yes" className="mr-2" onChange={(e) => handleConditionChange('aggressive', 'value', e.target.value)} />
                        Yes
                    </label>
                    <label className="flex items-center">
                        <input type="radio" name="aggressive" value="no" className="mr-2" onChange={(e) => handleConditionChange('aggressive', 'value', e.target.value)} />
                        No
                    </label>

                    <textarea
                        type="text"
                        name="aggressive_details"
                        className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="If aggressive behaviors exist, please describe the frequency, nature of aggressive behaviors, drugs used, and outcome."
                        disabled={formState.conditions.aggressive.value !== 'yes'}
                        value={formState.conditions.aggressive.details}
                        onChange={(e) => handleConditionChange('aggressive', 'details', e.target.value)}
                        maxLength="400"
                    />

                </div>

                <div className=" flex flex-col pt-4 ">
                    <h3 className="text-left font-semibold text-base text-gray-800 mb-2">GastroIntestinal Symptoms</h3>
                    <label className="flex items-center">
                        <input type="radio" name="gastrointestinal" value="yes" className="mr-2" onChange={(e) => handleConditionChange('gastroIntestinal', 'value', e.target.value)} />
                        Yes
                    </label>
                    <label className="flex items-center">
                        <input type="radio" name="gastrointestinal" value="no" className="mr-2" onChange={(e) => handleConditionChange('gastroIntestinal', 'value', e.target.value)} />
                        No
                    </label>

                    <textarea
                        type="text"
                        name="gastrointestinal_details"
                        className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="If evaluated for GastroIntestinal symptoms, please describe the nature of symptoms, drugs used, and outcome."
                        disabled={formState.conditions.gastroIntestinal.value !== 'yes'}
                        value={formState.conditions.gastroIntestinal.details}
                        onChange={(e) => handleConditionChange('gastroIntestinal', 'details', e.target.value)}
                        maxLength="400"
                    />


                </div>

                <div className="flex flex-col pt-4 ">
                    <h3 className="text-left font-semibold text-base text-gray-800 mb-2">Immunological Symptoms/Autoimmune Conditions</h3>
                    <label className="flex items-center">
                        <input type="radio" name="immunological" value="yes" className="mr-2" onChange={(e) => handleConditionChange('immunological', 'value', e.target.value)} />
                        Yes
                    </label>
                    <label className="flex items-center">
                        <input type="radio" name="immunological" value="no" className="mr-2" onChange={(e) => handleConditionChange('immunological', 'value', e.target.value)} />
                        No
                    </label>
                    <textarea
                        type="text"
                        name="immunological_details"
                        className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="If immunological symptoms exist, please describe the nature of symptoms, frequency, drugs, used and outcome. ie) IVIG, OR immunomodulators, JAK inhibitors, biologics, etc."
                        disabled={formState.conditions.immunological.value !== 'yes'}
                        value={formState.conditions.immunological.details}
                        onChange={(e) => handleConditionChange('immunological', 'details', e.target.value)}
                        maxLength="400"
                    />
                </div>

                <div className=" flex flex-col pt-4 ">
                    <h3 className="text-left font-semibold  text-base text-gray-800 mb-2  ">Mitochondrial/Metabolic Symptoms</h3>
                    <label className="flex items-center">
                        <input type="radio" name="mitochondrial" value="yes" className="mr-2" onChange={(e) => handleConditionChange('mitochondrial', 'value', e.target.value)} />
                        Yes
                    </label>
                    <label className="flex items-center">
                        <input type="radio" name="mitochondrial" value="no" className="mr-2" onChange={(e) => handleConditionChange('mitochondrial', 'value', e.target.value)} />
                        No
                    </label>

                    <textarea
                        type="text"
                        name="mitochondrial_details"
                        className="mt-1 col-span-2 h-24 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="If diagnosed with any mitochondrial or metabolic condition please describe drugs and supplements used and outcomes."
                        disabled={formState.conditions.mitochondrial.value !== 'yes'}
                        value={formState.conditions.mitochondrial.details}
                        onChange={(e) => handleConditionChange('mitochondrial', 'details', e.target.value)}
                        maxLength="400"
                    />
                </div>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4">
                <h3 className="text-left font-semibold text-base text-gray-800 mb-2">Please check any additional conditions your Patient may have</h3>
                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">
                    <h3 className="text-left font-semibold text-base text-gray-800">Sleep</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        <label className="flex items-center">
                            <input type="checkbox" name="sleep" value="sleeps_normally" className="mr-2" onChange={handleCheckboxChange} />
                            Sleeps normally (about 7 hours each night)
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="sleep" value="trouble_falling_asleep" className="mr-2" onChange={handleCheckboxChange} />
                            Has trouble falling asleep
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="sleep" value="trouble_staying_asleep" className="mr-2" onChange={handleCheckboxChange} />
                            Has trouble staying asleep
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="sleep" value="sleep_disorder" className="mr-2" onChange={handleCheckboxChange} />
                            Diagnosed with Sleep disorder
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="sleep" value="other" className="mr-2" onChange={handleCheckboxChange} />
                            Other
                        </label>
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">
                    <h3 className="text-left font-semibold text-base text-gray-800">Language</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        <label className="flex items-center">
                            <input type="checkbox" name="language" value="nonverbal_no_communication" className="mr-2" onChange={handleCheckboxChange} />
                            Nonverbal does not communicate
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="language" value="nonverbal_pecs" className="mr-2" onChange={handleCheckboxChange} />
                            Nonverbal communicates by PECS
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="language" value="nonverbal_spelling" className="mr-2" onChange={handleCheckboxChange} />
                            Nonverbal communicates by Spelling
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="language" value="speaks_phrases" className="mr-2" onChange={handleCheckboxChange} />
                            Speaks in phrases
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="language" value="speaks_sentences" className="mr-2" onChange={handleCheckboxChange} />
                            Speaks in sentences
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="language" value="normal_language" className="mr-2" onChange={handleCheckboxChange} />
                            Normal language
                        </label>
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">
                    <h3 className="text-left font-semibold text-base text-gray-800">Genetic Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        <label className="flex items-center">
                            <input type="checkbox" name="geneticDisorder" value="Chromosomal" className="mr-2" onChange={handleCheckboxChange} />
                            Chromosomal
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="geneticDisorder" value="Copy Number Variation" className="mr-2" onChange={handleCheckboxChange} />
                            Copy Number Variation
                        </label>
                        <label className="flex items-center">
                            <input type="checkbox" name="geneticDisorder" value="Single Gene Disorder" className="mr-2" onChange={handleCheckboxChange} />
                            Single Gene Disorder
                        </label>
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">
                    <h3 className="text-left font-semibold text-base text-gray-800">Syndromic</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Down', 'Fragile X', 'Phelan-McDermid', 'DiGeorge', 'CHARGE', 'Prader-Willi', 'Angelman', 'PTEN', 'Rett', 'Smith-Magenis', 'Sotos'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="syndromic" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Neurologic</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Epilepsy', 'Subclinical Discharges', 'ESES', 'Chiari Malformation', 'White Matter Abnormalities'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="neurologic" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Movement Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Tic Disorder', 'Tourette\'s Syndrome'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="movementDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Tone Abnormalities</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Hypertonia', 'Spasticity', 'Dystonia'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="toneAbnormalities" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Gastrointestinal Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Constipation', 'Diarrhea', 'GERD', 'Abdominal Pain', 'Eosinophilic Esophagitis', 'Gastritis', 'Enterocolitis', 'Crohns', 'UC'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="gastrointestinalDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Metabolic Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Mitochondria Dysfunction', 'Folate Metabolism Disorder', 'Cobalamin Metabolism Disorder', 'Carnitine Metabolism Disorder', 'Fatty Acid Oxidation Disorder'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="metabolicDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Nutritional Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Vit D Deficiency', 'Zinc Deficiency', 'Iron Deficiency'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="nutritionalDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Immune Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Autoimmune Encephalopathy', 'CIVD', 'Hypoimmunoglobulinema'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="immuneDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Attention Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Attention Disorder', 'Hyperactivity', 'Impulsivity'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="attentionDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Anxiety Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Avoidance', 'Explosive Behavior'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="anxietyDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Aggressive Behavior</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Hitting', 'Biting', 'Screaming'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="aggressiveBehavior" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Self Injuring Behavior</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Head Banging', 'Biting', 'Hitting'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="selfInjuringBehavior" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Sensory Modulation Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Hyposensitivity', 'Hypersensitivity'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="sensoryModulationDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Sleep Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Sleep Onset', 'Sleep Maintenance', 'Sleep Disordered Breathing', 'Periodic Limb Movements During Sleep'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="sleepDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">
                    <h3 className="text-left font-semibold text-base text-gray-800">Growth Disorder</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Failure to Thrive', 'Overgrowth', 'Macrocephaly', 'Microcephaly'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="growthDisorder" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col mt-2 shadow-lg px-4 py-3 border-2 border-gray-300">

                    <h3 className="text-left font-semibold text-base text-gray-800">Coordination</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8">
                        {['Ataxia', 'Visual Motor Apraxia'].map((item) => (
                            <label className="flex items-center" key={item}>
                                <input type="checkbox" name="coordination" value={item} className="mr-2" onChange={handleCheckboxChange} />
                                {item}
                            </label>
                        ))}
                    </div>
                </div>
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto mt-8">
                <h3 className="text-left font-semibold text-base text-gray-800">As of today, what are your pressing medical concerns? What are the concerns that most impact functioning, independence or quality of life? How can the panel help you?</h3>

                <textarea
                    type="text"
                    name="closing_remarks"
                    className="h-40 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077] mt-2"
                    placeholder=""
                    value={formState.closing_remarks}
                    onChange={(e) => setFormState({ ...formState, closing_remarks: e.target.value })}
                    maxLength="400"
                />
            </div>

            <div className="w-4/5 md:w-3/5 mx-auto flex mt-8 justify-end">
                <button className="bg-[#2b2138] text-white font-semibold px-4 py-2 rounded-2xl flex items-center justify-center" onClick={uploadFile}>Submit</button>
            </div>
            {isModalVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="w-[90%] md:w-[45rem] xl:w-[60rem] p-10 bg-white rounded shadow-lg">
                        <h2 className="text-xl text-center font-semibold mb-4">Success!!</h2>
                        <p>Thank you for completing your case study form. Your informaiton has been saved and will be passed onto our team for consideration for our panel discussion. We look forward to seeing you at Synchrony 2024!</p>
                        <div className="w-full flex items-center justify-center mt-8">
                            <button
                                className="mt-4 bg-[#2b2138] text-white font-semibold px-4 py-2 rounded-2xl"
                                onClick={() => {
                                    setIsModalVisible(false);
                                    navigate('/');
                                }}
                            >
                                Return to The Brain Registry
                            </button>
                        </div>
                    </div>
                </div>
            )
            }

        </div >
    );
}

export default Form;

const Highlighted2 = () => {

    const name = 'The TAPESTRY study';
    const projAims = 'To establish if AB-2004 can improve irritability in autism. AB-2004 is a therapeutic agent designed to selectively adsorb substances produced by bacteria in the gut and reduce their ability to enter the bloodstream and contribute to irritability. This study builds upon research that has shown a significant difference in the gut bacteria in autism compared to controls, as well as the link between gut bacteria and the brain which could influence behavioral characteristics, including irritability.';
    const leadInves = 'Axial Therapeutics';
    const locations = 'United States, Australia and New Zealand';
    const ages = '13 to 17';
    const addCrit = 'Irritability + GI symptoms (constipation, diarrhea, or bloating)';
    const website = 'theautismstudy.com';


    return (
        <div className={`w-full mt-4 bg-white p-6 rounded-2xl shadow-xl flex flex-row`}>

            <div className="w-full">
                <div className="mb-2 flex flex-row items-center">
                <img src={require('../Assets/2.jpeg')}  alt="test" className="h-20 mr-4 " />
                    <h3 className="text-xl font-bold text-left">{name}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Project Aims:</h3>
                    <div>
                        {projAims}
                    </div>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Lead Investigator/Institution:</h3>
                    <h3 className="text-left">{leadInves}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Location(s):</h3>
                    <h3 className="text-left">{locations}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Website:</h3>
                    <h3 className="text-left">{website}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Ages:</h3>
                    <h3 className="text-left">{ages}</h3>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Additional Criteria for Inclusion:</h3>
                    <h3 className="text-left">
                        {addCrit.split(';').map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                        ))}
                    </h3>
                </div>

                {/* 
            <div className="mt-2 mb-2">
                <h3 className="font-semibold text-left">For more information please visit: <a href={Link} className="text-blue-500 hover:text-blue-700">{Link}</a></h3>
            </div> */}
            </div>
        </div>
    );
}

export default Highlighted2;


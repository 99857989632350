import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from 'src/Common/Components/Navbar';
import { resetPassword } from 'aws-amplify/auth';

function GenerateCode() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');

async function handleResetPassword(username) {
    try {
        const output = await resetPassword({ username });
        handleResetPasswordNextSteps(output);
    } catch (error) {
      console.log(error);
    }
  }

function handleResetPasswordNextSteps(output) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
            `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        );
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
        case 'DONE':
        console.log('Successfully reset password.');
        break;
    }
    navigate('/forgotPassword')
}

  return (
    <div className="w-full">

      <NavBar />

      <div className="shadow-lg border-2 w-[26rem] lg:w-[48rem] flex flex-col lg:flex-row rounded-2xl mt-12 p-8 mx-auto justify-center">

        <div className="w-full lg:w-[19.25rem] mr-4">
          <div className="flex flex-row items-center">
            <img className="w-[5rem]" src={require('../ValidateLogin/Assets/Mail.png')} />
            <h3 className="ml-2 font-medium text-lg">Reset Your Password</h3>
          </div>
          <h5 className="mt-2 lg:mt-6 text-base">A verification code will be sent to your email. Please check your inbox and enter your email address and verification code to continue.</h5>
        </div>

        <div className="w-[22rem] mt-6 lg:mt-0 lg:ml-6">
          <div className="w-[22rem] mx-auto flex flex-col items-center ">
            <label htmlFor="email" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" placeholder="Your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>


          <div className="w-[22rem] mx-auto flex flex-col items-end">
            <button
              type="submit"
              className="w-[5rem] bg-orange-500 text-white py-2 rounded-2xl mt-4 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              onClick={(e) => handleResetPassword(email)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default GenerateCode;

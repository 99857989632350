import { useState } from 'react';
import { confirmSignUp } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import NavBar from 'src/Common/Components/Navbar';
import { resetPassword } from 'aws-amplify/auth';
import { confirmResetPassword } from 'aws-amplify/auth';
import NoVis from './Assets/NoVis.svg';
import Vis from './Assets/Vis.svg';

function ForgotPassword() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [authenticationCode, setAuthenticationCode] = useState('');
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
};

const toggleConfirmPassVisibility = () => {
    setIsConfirmPassVisible(!isConfirmPassVisible);
};

async function handleConfirmResetPassword(
    username,
    confirmationCode,
    newPassword
    ) {
    try {
        await confirmResetPassword({ username, confirmationCode, newPassword });
    } catch (error) {
        console.log(error);
    }

    navigate('/signin');
}

  return (
    <div className="w-full">

      <NavBar />

      <div className="shadow-lg border-2 w-[26rem] lg:w-[48rem] flex flex-col lg:flex-row rounded-2xl mt-12 p-8 mx-auto justify-center">

        <div className="w-full lg:w-[19.25rem] mr-4">
          <div className="flex flex-row items-center">
            <img className="w-[5rem]" src={require('../ValidateLogin/Assets/Mail.png')} />
            <h3 className="ml-2 font-medium text-lg">Reset Your Password</h3>
          </div>
          <h5 className="mt-2 lg:mt-6 text-base">A verification code has been sent to your email. Please check your inbox and enter your email address and verification code to continue.</h5>
        </div>

        <div className="w-[22rem] mt-6 lg:mt-0 lg:ml-6">
          <div className="w-[22rem] mx-auto flex flex-col items-center ">
            <label htmlFor="email" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" placeholder="Your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="w-[22rem] mx-auto flex flex-col mt-6 items-center">
            <label htmlFor="confirmation" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Confirmation Code</label>
            <input
              type="confirmation"
              id="confirmation"
              name="confirmation"
              className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" placeholder="Your email"
              required
              value={authenticationCode}
              onChange={(e) => setAuthenticationCode(e.target.value)}
            />
          </div>

          <div className="w-[22rem] mx-auto flex flex-col mt-6 items-center">
            <label htmlFor="password" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Password</label>
            <div className="relative w-full">
                <input
                    type={isPasswordVisible ? 'text' : 'password'}
                    id="password"
                    name="password"
                    className="block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" // Added pr-10 for padding on the right
                    placeholder="Password"
                    required
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                />
                <button onClick={togglePasswordVisibility} className="absolute right-2 top-1/2 transform -translate-y-1/3">
                    <img src={isPasswordVisible ? NoVis : Vis} alt="Visibility Toggle" className="w-6 h-6" />
                </button>
            </div>
        </div>

        <div className="w-[22rem] mx-auto flex flex-col mt-6 items-center">
            <label htmlFor="confirmPass" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Confirm Password</label>
            <div className="relative w-full">
                <input
                    type={isConfirmPassVisible ? 'text' : 'password'}
                    id="confirmPass"
                    name="confirmPass"
                    className="block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" // Added pr-10 for padding on the right
                    placeholder="Confirm Password"
                    required
                    value={confirmPassword}
                    onChange={event => setConfirmPassword(event.target.value)}
                />
                <button onClick={toggleConfirmPassVisibility} className="absolute right-2 top-1/2 transform -translate-y-1/3">
                    <img src={isConfirmPassVisible ? NoVis : Vis} alt="Visibility Toggle" className="w-6 h-6" />
                </button>
            </div>
        </div>

          <div className="w-[22rem] mx-auto flex flex-col items-end">
            <button
              type="submit"
              className="w-[5rem] bg-orange-500 text-white py-2 rounded-2xl mt-4 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              onClick={(e) => handleConfirmResetPassword(email,authenticationCode,password)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ForgotPassword;

const Highlighted = () => {

    const name = 'ML-004 in Adolescents and Adults with ASD';
    const projAims = 'Phase 2 randomized, double blind, placebo-controlled trial to assess whether there is an impact of ML-004 compound on the core social communication deficits and on the associated features of irritability in ASD.';
    const leadInves = 'MapLight Therapeutics';
    const locations = 'Various centers across US, Canada and Australia (click here for the list of locations)';
    const ages = '12 to 45';
    const addCrit = 'Age 12 to 45 at screening; Has a designated care/study partner who can reliably report on symptoms; Has a diagnosis of ASD; Full scale IQ (or equivalent) ≥70 score; Psychoactive medications and adjunctive therapies are stable for 4 weeks prior to screening; Must be able to swallow study medication';
    const contact = 'Contact: Kelly Amaguin +1 650-839-4388 or email kamaguin@maplightrx.com';


    return (
        <div className={`w-full mt-4 bg-white p-6 rounded-2xl shadow-xl flex flex-row`}>

            <div className="w-full">
                <div className="mb-2 flex flex-row items-center">
                <img src={require('../Assets/1.jpeg')} alt="ML-004" className="h-20 mr-4 " />
                    <h3 className="text-xl font-bold text-left">{name}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Project Aims:</h3>
                    <div>
                        {projAims}
                    </div>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Lead Investigator/Institution:</h3>
                    <h3 className="text-left">{leadInves}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Location(s):</h3>
                    <h3 className="text-left">{locations}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Contacts:</h3>
                    <h3 className="text-left">{contact}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Ages:</h3>
                    <h3 className="text-left">{ages}</h3>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Additional Criteria for Inclusion:</h3>
                    <h3 className="text-left">
                        {addCrit.split(';').map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                        ))}
                    </h3>
                </div>

                {/* 
            <div className="mt-2 mb-2">
                <h3 className="font-semibold text-left">For more information please visit: <a href={Link} className="text-blue-500 hover:text-blue-700">{Link}</a></h3>
            </div> */}
            </div>
        </div>
    );
}

export default Highlighted;



import { useState, useEffect } from "react";
import { signIn, signOut } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { signedIn } from 'aws-amplify/auth'
import { getCurrentUser } from 'aws-amplify/auth';
import NoVis from '../Assets/NoVis.svg'
import Vis from '../Assets/Vis.svg'
import { useUser } from "src/UserContext";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resendSignUpCode } from 'aws-amplify/auth';
import { resetPassword } from 'aws-amplify/auth';
import { confirmResetPassword } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AuthError } from 'aws-amplify/auth';
import { jwtDecode } from 'jwt-decode';

const LoginEntry = () => {
    

    const { isSignedIn, setIsSignedIn } = useUser();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [authenticationCode, setAuthenticationCode] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        console.log('Toggle password visibility');
        setIsPasswordVisible(!isPasswordVisible);
    };

    useEffect(() => {
        currentSession();
    })

    async function currentSession() {
        try {
          const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
          if (accessToken) {
          const decodedToken = jwtDecode(accessToken.toString());
          //console.log("DecodedToken: ", decodedToken);
          return  {
            emailVerified: decodedToken.email_verified,
            username: decodedToken.username,
            accessToken,
            idToken
          }
        }
        //   console.log("AccessToken: ", accessToken)
        //   console.log("IdToken: ", idToken)
        } catch (err) {
          console.log(err);
        }
      }


//     async function getAccessToken() {
//     try {
//         const session = await currentSession();
//         const accessToken = session.getAccessToken().getJwtToken();
//         console.log('Access Token:', accessToken);
//         return accessToken;
//     } catch (error) {
//         console.error('Error getting access token:', error);
//     }
// }



    const showError = (errorMessage) => {
        // const errorMessage = typeof error === 'string' ? error : 'Please Login To Continue.';
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };

    const handleResendCode = async (username) => {
    const {
        destination,
        deliveryMedium,
        attributeName
    } = await resendSignUpCode({username});
    }

    async function handleLogin(event) {
        console.log("please", isSignedIn);

      event.preventDefault(); // Prevent default form submission
    
      try {

        const { nextStep, signedIn } = await signIn({
          username: email, // Use the email from state as the username
          password: password // Use the password from state
        });

        console.log("nextStep: ", nextStep);

        if (nextStep.signInStep == 'CONFIRM_SIGN_UP') {
            showError("Please verify your email before logging in!");
            if (email && password) {
                handleResendCode(email);
            }
            navigate('/validatePage');
        }
        
        const user = await getCurrentUser();
        console.log(user);
        setIsSignedIn(true);
        navigate('/profile')
      } catch (error) {
        console.log('Error confirming sign up', error);
        showError('Username/Password does not exist');
        // if (error == 'UserNotFoundException: User does not exist.') {
        //     console.log(error);
        //     showError("User does not exist!");
        // }
      }
    }

    const handleSignOut = async () => {
        try {
        await signOut();
        navigate('/');
        setIsSignedIn(false)
        } catch (error) {
        console.log('Error signing out: ', error);
        }
    };

    return (
        <div className="bg-transparent w-full h-screen">
            <div className='text-center'>
                <div className="px-6">
                    <h2 className="text-2xl text-[#005077] font-extrabold mt-12 ">Login to The Brain Foundation</h2>
                </div>
                <form className='mt-4 flex flex-col mx-auto items-center w-full xs:w-[26rem]'>
                    <div className="flex flex-col w-full p-6">
                        <label className="w-full text-left font-medium text-base text-gray-800 mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            className="block py-2.5 px-4 w-full xs:w-[26rem] text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-full px-6 relative">
                        <label className="w-full text-left font-medium text-base text-gray-800 mb-2">
                            Password
                        </label>
                        <input
                            type={isPasswordVisible ? 'text' : 'password'}
                            className="block py-2.5 px-4 w-full xs:w-[26rem] text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}

                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute right-8 xs:-right-2 top-14 transform -translate-y-1/2"
                        >
                            <img src={isPasswordVisible ? NoVis : Vis} alt="Visibility Toggle" className="w-6 h-6" />
                        </button>
                    </div>

                    <div className="p-6 mb-2 flex flex-row justify-between gap-6 w-full xs:w-[26rem]">
                        <div className="">
                            <input className="mr-4" type="checkbox" />
                            <span className="text-base text-[#2b2138]">
                                Remember Me
                            </span>
                        </div>
                        <div className="">
                            <a className="text-base text-[#2b2138] hover:text-blue-800" href="#">
                                <button onClick={(e) => navigate('/generateCode')}>Forgot Password?</button>
                            </a>
                        </div>
                    </div>
                    
                    <div className="w-full px-6">
                        <button
                            type="submit"
                            className="bg-[#FFA400] w-full xs:w-[26rem] h-12 mt-2 text-white font-bold rounded-2xl focus:outline-none focus:shadow-outline"
                            onClick={handleLogin}
                        >
                            Login
                        </button>
                    </div>

                    <div className="p-6 mb-2 items-center place-items-center flex w-[26rem]">
                        <div className="text-center">
                            <span className="text-center text-[#2b2138]">
                                Don't have an account yet? 
                                {/* <a class="text-center text-blue-400 hover:text-blue-800" href="#"> */}
                                    <Link className="text-center text-blue-400 hover:text-blue-800" href="#" to="/"> Sign up here </Link> 
                                {/* </a> */}
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default LoginEntry;













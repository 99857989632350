/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchClinicalTrialsLists = /* GraphQL */ `
  query SearchClinicalTrialsLists(
    $filter: SearchableClinicalTrialsListFilterInput
    $sort: [SearchableClinicalTrialsListSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClinicalTrialsListAggregationInput]
  ) {
    searchClinicalTrialsLists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        nctId
        officialTitle
        briefSummary
        orgName
        status
        startDate
        completionDate
        maxAge
        minAge
        maxAgeFloat
        minAgeFloat
        sex
        eligibilityCriteria
        studyLink
        isbrainfoundation
        lastUpdatePostDate
        studyFirstPostDate
        locationArray {
          zip
          country
          state
          facility
          city
          contacts {
            name
            phone
            email
            phoneExt
          }
        }
        centralContacts {
          name
          phone
          email
          phoneExt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getClinicalTrialsList = /* GraphQL */ `
  query GetClinicalTrialsList($nctId: String!) {
    getClinicalTrialsList(nctId: $nctId) {
      nctId
      officialTitle
      briefSummary
      orgName
      status
      startDate
      completionDate
      maxAge
      minAge
      maxAgeFloat
      minAgeFloat
      sex
      eligibilityCriteria
      studyLink
      centralContacts {
        name
        phone
        email
        phoneExt
        __typename
      }
      locationArray {
        zip
        country
        state
        facility
        city
        __typename
      }
      isbrainfoundation
      lastUpdatePostDate
      studyFirstPostDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClinicalTrialsLists = /* GraphQL */ `
  query ListClinicalTrialsLists(
    $nctId: String
    $filter: ModelClinicalTrialsListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClinicalTrialsLists(
      nctId: $nctId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        nctId
        officialTitle
        briefSummary
        orgName
        status
        startDate
        completionDate
        maxAge
        minAge
        maxAgeFloat
        minAgeFloat
        sex
        eligibilityCriteria
        studyLink
        isbrainfoundation
        lastUpdatePostDate
        studyFirstPostDate
        locationArray
        centralContacts
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      middleName
      lastName
      dob
      sex
      gender
      ethnicity
      email
      phoneNumber
      streetAddress1
      streetAddress2
      city
      state
      postalCode
      country
      chosenComorbidities
      interests
      consent
      relationship
      bookmarkedTrials
      isAccountHolder
      isPatient
      patientIDList
      accountHolderId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        dob
        sex
        gender
        ethnicity
        email
        phoneNumber
        streetAddress1
        streetAddress2
        city
        state
        postalCode
        country
        chosenComorbidities
        interests
        consent
        relationship
        bookmarkedTrials
        isAccountHolder
        isPatient
        patientIDList
        accountHolderId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

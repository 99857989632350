import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from 'src/Common/Components/Navbar';
import Highlighted from '../Explore/Components/Highlighted';
import Highlighted2 from '../Explore/Components/Highlighted2';
import Highlighted3 from '../Explore/Components/Highlighted3';
import Highlighted4 from '../Explore/Components/Highlighted4';
import Highlighted5 from '../Explore/Components/Highlighted5';
import Next from '../Explore/Assets/Next.png';
import Prev from '../Explore/Assets/Prev.png';

const Search = () => {

    const [Status, setStatus] = useState('All');
    const [StatusOption, setStatusOption] = useState('All');
    const [Keywords, setKeywords] = useState('');
    const [location, setLocation] = useState('');
    // const [Distance, setDistance] = useState('');
    const [Age, setAge] = useState('');
    const [Sex, setSex] = useState('All');
    const [filteredTrials, setFilteredTrials] = useState([]);
    const navigate = useNavigate();
    const handleFilter = () => {
        const queryParams = `?keyword=${Keywords}&location=${location}&Age=${Age}&gender=${Sex}&status=${Status}`;
        navigate(`/searchresults${queryParams}`);
    };
    const [isLoading, setIsLoading] = useState(false);

    // const getLocation = () => {
    //     setIsLoading(true);
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(async (position) => {
    //             const { latitude, longitude } = position.coords;
    //             try {
    //                 let key = process.env.REACT_APP_geo
    //                 const response = await fetch(
    //                     `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${key}`
    //                 );
    //                 if (!response.ok) {
    //                     throw new Error('Failed to fetch location data.');
    //                 }
    //                 const data = await response.json();
    //                 if (data.features && data.features.length > 0) {
    //                     const placeData = data.features[0].context;
    //                     let city, state, country;
    //                     placeData.forEach(item => {
    //                         if (item.id.startsWith('place.') && !city) {
    //                             city = item.text;
    //                         } else if (item.id.startsWith('region.') && !state) {
    //                             state = item.text;
    //                         } else if (item.id.startsWith('country.') && !country) {
    //                             country = item.text;
    //                         }
    //                     });
    //                     const addy = city + ", " + state + ", " + country;
    //                     setLocation(`${addy}`);
    //                 } else {
    //                     setLocation('Location not found');
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching location data:', error);
    //                 setLocation('Location not found');
    //             } finally {
    //                 setIsLoading(false);
    //             }
    //         });
    //     } else {
    //         alert('Geolocation is not supported by your browser.');
    //     }
    // };

    const [currentIndex, setCurrentIndex] = useState(0);
    const highlightedComponents = [Highlighted, Highlighted2, Highlighted3, Highlighted4, Highlighted5];

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? highlightedComponents.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === highlightedComponents.length - 1 ? 0 : prevIndex + 1));
    };

    const CurrentHighlightedComponent = highlightedComponents[currentIndex];


    const [clicked, setClicked] = useState(false);

    const handleDown = (tab) => {
        setClicked(true);
    };

    useLayoutEffect(() => {
        if (clicked) {
            const targetSection = document.getElementById('scrollToSection');
            if (targetSection) {
                targetSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
                setClicked(false);
            }
        }
    }, [clicked]);

    return (
        <div className="w-full min-h-screen  bg-[#F5F4F4]">
            <NavBar />
            <div className="flex flex-col items-center w-full">
                <button
                    className="absolute top-20 right-1 w-[8rem] bg-orange-500 text-[12px] text-white p-1 rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                    onClick={handleDown}
                >
                    Show Partnered Trials!
                </button>

                <div className="w-[32rem] md:w-[40rem] lg:w-[60rem]">
                    <div className="pt-12 flex flex-wrap justify-center">
                        <h3 className=" text-center text-2xl text-extrabold text-[#005077]">Search For a Clinical Trial</h3>
                    </div>
                    <div className="flex flex-wrap w-full justify-center pb-2">
                        <div className="w-4/5 ">
                            <h3 className=" text-left text-md px-4 pt-4">Our Clinical Trials Search Tool allows you to explore studies from ClinicalTrials.gov, a government website and online database of clinical research studies.
                                Easily filter through all availible clinical trials using any of the optional fields.
                                <br />
                                <br />
                                Please note that the studies listed on this page are not run or organised by the BRAIN Foundation and we are unable to reply to email enquiries about clinical trials. We attempt to give as much details as possible below, but if you have any further questions please contact the study organisers directly.</h3>
                        </div>
                    </div>


                    <div className="w-4/5  mx-auto flex flex-col p-4">
                        <div className="w-full">
                            <label htmlFor="Keywords" className="text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Keywords</label>
                            <input
                                type="text"
                                id=" Keywords"
                                name="Keywords"
                                className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                                placeholder=""
                                value={Keywords}
                                onChange={(e) => setKeywords(e.target.value)}
                            // list="keywordSuggestions" // Reference the id of the datalist element
                            />
                            {/* <datalist id="keywordSuggestions"> */}
                            {/* Map through your suggestions array to generate option elements */}
                            {/* {keywords.map((keyword, index) => (
                                    <option key={index} className='w-4/5' value={keyword} />
                                ))}
                            </datalist> */}
                        </div>
                    </div>

                    <div className="w-4/5  mx-auto flex p-4 relative">
                        {/* <div className="w-full lg:w-1/4">
                            <label htmlFor="Distance" className="text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Max distance (miles)</label>
                            <input
                                type="number"
                                id="Distance"
                                name="Distance"
                                className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                                placeholder="100"
                                value={Distance}
                                onChange={(e) => setDistance(e.target.value)}
                            />
                        </div> */}

                        {/* <div className="w-full lg:ml-4 lg:w-3/4 pt-8 lg:pt-0"> */}
                        {/* <div className="w-full pt-8">
                                <label htmlFor="location" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Location</label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        id="location"
                                        className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                                        placeholder="Your location"
                                        required
                                        value={isLoading ? 'Fetching your location...' : location}
                                        onChange={(e) => setLocation(e.target.value)}
                                    />
                                    <button
                                        className="absolute right-0 top-1/2 transform -translate-y-4 -translate-x-1  w-8 h-8 rounded-xl focus:outline-none bg-transparent flex items-center justify-center"
                                        onClick={getLocation}
                                    >
                                        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.50008 0C3.81285 0 0 3.58856 0 8.00007C0 9.43715 0.719068 11.2501 2.19268 13.5438C3.41776 15.4488 4.9357 17.3412 6.2718 19.008C6.88877 19.7767 7.47023 20.5036 7.91854 21.1052C8.0517 21.2848 8.2692 21.3893 8.5 21.3893C8.7308 21.3893 8.94831 21.2848 9.08146 21.1052C9.52977 20.5036 10.1112 19.7767 10.7282 19.008C12.0642 17.3411 13.5778 15.4487 14.8073 13.5438C16.2809 11.2503 17 9.43724 17 8.00007C17 3.58856 13.1872 0 8.49992 0L8.50008 0ZM8.50008 11.6972C6.33391 11.6972 4.57189 10.0387 4.57189 8.00007C4.57189 5.96145 6.33405 4.30296 8.50008 4.30296C10.6661 4.30296 12.4283 5.96145 12.4283 8.00007C12.4283 10.0387 10.6661 11.6972 8.50008 11.6972Z" fill="#1F0227" />
                                        </svg>
                                    </button>
                                </div>
                            </div> */}

                        <div className="w-full">
                            <label htmlFor="location" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Location</label>
                            <div className="relative">
                                <select
                                    id="location"
                                    className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    required
                                >
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="Canada">Canada</option>
                                    <option value="China">China</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="France">France</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>

                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="w-4/5  mx-auto flex flex-col lg:flex-row p-4 relative">

                        <div className="w-full lg:w-1/4 flex flex-col">
                            <label htmlFor="Sex" className="text-left font-medium text-base lg:text-lg text-gray-800">Sex</label>
                            <select
                                id="Sex"
                                className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                                value={Sex}
                                onChange={(e) => setSex(e.target.value)}>
                                <option value="All">All</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>

                        <div className="w-full lg:ml-4 lg:w-1/4 mt-6 lg:mt-0">
                            <label htmlFor="Age" className="text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Age</label>
                            <input
                                type="text"
                                id="Age"
                                name="Age"
                                className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                                value={Age}
                                onChange={(e) => setAge(e.target.value)}
                            />
                        </div>

                        <div className="w-full lg:ml-4 lg:w-1/2 pt-8 lg:pt-0">
                            <label htmlFor="Status" className="text-left font-medium text-base lg:text-lg text-gray-800">Trial Status</label>
                            <select
                                id="Status"
                                className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                                value={Status}
                                onChange={(e) => setStatus(e.target.value)}>
                                <option value="All">All Studies</option>
                                <option value="RNR">Recruiting / Not yet recruiting</option>
                            </select>
                        </div>
                    </div>

                    <div className="w-4/5 mx-auto flex flex-col p-4 items-center pb-10 border-b-2 border-stone-400">
                        <button
                            type="submit"
                            className="w-full bg-orange-500 text-white p-6 rounded-2xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                            onClick={handleFilter}
                        >
                            Search
                        </button>
                    </div>
                </div>


                <div id="scrollToSection" className="mt-8 lg:mt-0 w-full flex flex-col pb-60 items-center">
                    <div className="pt-12 w-[32rem] md:w-[40rem] lg:w-[60rem] flex flex-wrap justify-center ">
                        <h3 className=" text-center text-2xl text-extrabold text-[#005077] ">Autism Treatment Trials</h3>
                    </div>
                    <div className="flex w-[32rem] md:w-[40rem] lg:w-[60rem] flex-wrap w-full justify-center pb-2">
                        <div className="w-4/5 ">
                            <h3 className=" text-left text-md px-4 pt-4">Explore some of the trials that The Brain Foundation has partnered with. These are currently taking place in
                                the US and are actively recruiting participants.
                                <br />
                                <br />
                                Please note that the studies listed on this page are not run or organized by the BRAIN Foundation, and we are
                                unable to reply to email inquiries about clinical trials. We attempt to give as much detail as possible below,
                                but if you have any further questions, please contact the study organizers directly.</h3>
                        </div>
                    </div>


                    <div className="flex items-center w-full sm:w-[32rem] md:w-[40rem] lg:w-[60rem]  mx-auto mt-2 lg:px-12 ">
                        <button onClick={handlePrevClick}>
                            <img src={Prev} alt="Previous" className="m-2 h-10 w-5" />
                        </button>
                        <div className="w-full p-4 h-fit">
                            <CurrentHighlightedComponent />
                        </div>
                        <button onClick={handleNextClick}>
                            <img src={Next} alt="Next" className="m-2 h-10 w-5" />
                        </button>
                    </div>

                </div>
            </div>
        </div >
    );
};
export default Search;


export const comorbiditiesState = {
    intellectualDisabilityLevel: [],
    languageLevel: [],
    geneticDisorder: [],
    syndromic: [],
    neurologic: [],
    movementDisorder: [],
    toneAbnormalities: [],
    gastrointestinalDisorder: [],
    metabolicDisorder: [],
    nutritionalDisorder: [],
    immuneDisorder: [],
    attentionDisorder: [],
    anxietyDisorder: [],
    aggressiveBehavior: [],
    selfInjuringBehavior: [],
    sensoryModulationDisorder: [],
    sleepDisorder: [],
    growthDisorder: [],
    coordination: [],
};

export const comorbidityTypesString = {
        intellectualDisabilityLevel: 'Intellectual Disability Level',
        languageLevel: 'Language Level',
        geneticDisorder: 'Genetic Disorder',
        syndromic: 'Syndromic',
        neurologic: 'Neurologic',
        movementDisorder: 'Movement Disorder',
        toneAbnormalities: 'Tone Abnormalities',
        gastrointestinalDisorder: 'Gastrointestinal Disorder',
        metabolicDisorder: 'Metabolic Disorder',
        nutritionalDisorder: 'Nutritional Disorder',
        immuneDisorder: 'Immune Disorder',
        attentionDisorder: 'Attention Disorder',
        anxietyDisorder: 'Anxiety Disorder',
        aggressiveBehavior: 'Aggressive Behaviour',
        selfInjuringBehavior: 'Self Injuring Behaviour',
        sensoryModulationDisorder: 'Sensory Modulation Disorder',
        sleepDisorder: 'Sleep Disorder',
        growthDisorder: 'Growth Disorder',
        coordination: 'Coordination',

    };

    export const comorbidityTypesStringReverse = {
        'Intellectual Disability Level': 'intellectualDisabilityLevel',
        'Language Level': 'languageLevel',
        'Genetic Disorder': 'geneticDisorder',
        'Syndromic': 'syndromic',
        'Neurologic': 'neurologic',
        'Movement Disorder': 'movementDisorder',
        'Tone Abnormalities': 'toneAbnormalities',
        'Gastrointestinal Disorder': 'gastrointestinalDisorder',
        'Metabolic Disorder': 'metabolicDisorder',
        'Nutritional Disorder': 'nutritionalDisorder',
        'Immune Disorder': 'immuneDisorder',
        'Attention Disorder': 'attentionDisorder',
        'Anxiety Disorder': 'anxietyDisorder',
        'Aggressive Behaviour': 'aggressiveBehavior',
        'Self Injuring Behaviour': 'selfInjuringBehavior',
        'Sensory Modulation Disorder': 'sensoryModulationDisorder',
        'Sleep Disorder': 'sleepDisorder',
        'Growth Disorder': 'growthDisorder',
        'Coordination': 'coordination',
        
    };

    export const comorbidityTypesArray = [
        'Intellectual Disability Level',
        'Language Level',
        'Genetic Disorder',
        'Syndromic',
        'Neurologic',
        'Movement Disorder',
        'Tone Abnormalities',
        'Gastrointestinal Disorder',
        'Metabolic Disorder',
        'Nutritional Disorder',
        'Immune Disorder',
        'Attention Disorder',
        'Anxiety Disorder',
        'Aggressive Behaviour',
        'Self Injuring Behaviour',
        'Sensory Modulation Disorder',
        'Sleep Disorder',
        'Growth Disorder',
        'Coordination',
    ];

    export const comorbidityTypesOptions = {
        intellectualDisabilityLevel: ['Borderline', 'Mild', 'Moderate', 'Severe'],
        languageLevel: ['Mild', 'Moderate', 'Severe', 'Non-Verbal'],
        geneticDisorder: ['Chromosomal', 'Copy Number Variation', 'Single Gene Disorder'],
        syndromic: ['Down', 'Fragile X', 'Phelan-McDermid', 'DiGeorge', 'CHARGE', 'Prader-Willi', 'Angelman', 'PTEN', 'Rett', 'Smith-Magenis', 'Sotos'],
        neurologic: ['Epilepsy', 'Subclinical Discharges', 'ESES', 'Chiari Malformation', 'White Matter Abnormalities'],
        movementDisorder: ['Tic Disorder', 'Tourrette\'s Syndrome'],
        toneAbnormalities: ['Hypertonia', 'Spasticity', 'Dystonia'],
        gastrointestinalDisorder: ['Constipation', 'Diarrhea', 'GERD', 'Abdominal Pain', 'Eosinophilic Esophagitis', 'Gastritis', 'Enterocolitis', 'Crohns', 'UC'],
        metabolicDisorder: ['Mitochondria Dysfunction', 'Folate Metabolism Disorder', 'Cobalamin Metabolism Disorder', 'Carnitine Metabolism Disorder', 'Fatty Acid Oxidation Disorder'],
        nutritionalDisorder: ['Vit D Deficiency', 'Zinc Deficiency', 'Iron Deficiency'],
        immuneDisorder: ['Autoimmune Encephalopathy', 'CIVD', 'Hypoimmunoglobulinema'],
        attentionDisorder: ['Attention Disorder', 'Hyperactivity', 'Impulsivity'],
        anxietyDisorder: ['Avoidance', 'Explosive Behavior'],
        aggressiveBehavior: ['Hitting', 'Biting', 'Screaming'],
        selfInjuringBehavior: ['Head Banging', 'Biting', 'Hitting'],
        sensoryModulationDisorder: ['Hyposensitivity', 'Hypersensitivity'],
        sleepDisorder: ['Sleep Onset', 'Sleep Maintenance', 'Sleep Disordered Breathing', 'Periodic Limb Movements During Sleep'],
        growthDisorder: ['Failure to Thrive', 'Overgrowth', 'Macrocephaly', 'Microcephaly'],
        coordination: ['Ataxia', 'Visual Motor Apraxia'],
    };

    
    

import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';


const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {

  const [isSignedIn, setIsSignedIn] = useState(false);

  async function currentAuthenticatedUser() {
    try {
      const { username } = await getCurrentUser();
      if (username) {
        setIsSignedIn(true)
        //console.log('from context: ', username);
        return true
      }
    } catch (err) {
      //console.log(err);
      setIsSignedIn(false)
      return false
    }
  }

  useEffect(() => {currentAuthenticatedUser();})



  return (
    <UserContext.Provider value={{ isSignedIn, setIsSignedIn }}>
      {children}
    </UserContext.Provider>
  );
};



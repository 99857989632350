import React, { useEffect, useState } from 'react';
// import { deleteUser } from 'src/graphql/mutations.js'
import { generateClient } from 'aws-amplify/api'
import { useNavigate } from 'react-router-dom';
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import { getUserByEmail } from 'src/graphql/queries';
import NoVis from 'src/assets/NoVis.svg';
import Vis from 'src/assets/Vis.svg';
import { updatePassword } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import { deleteUser } from 'aws-amplify/auth';
import { useUser } from 'src/UserContext';


const client = generateClient();
toast.configure();


const EditSettings = () => {

    const navigate = useNavigate();

    const Popup = ({ message, onClose }) => {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-md text-center">
                    <p className="text-lg text-left mb-4">{message}</p>
                    <div className="flex justify-center">
                        <button className="bg-blue-500 text-white p-2 rounded" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    const [highContrastMode, setHighContrastMode] = useState(false);
    const [subscriptionFrequency, setSubscriptionFrequency] = useState('monthly');
    const [optedOut, setOptedOut] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [userDataFromCognito, setUserDataFromCognito] = useState(null);

    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const { isSignedIn, setIsSignedIn } = useUser();

      //UI RELATED COMPONENTS
    const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
    const [isNewPassVisible, setIsNewPassVisible] = useState(false);

    const toggleOldPasswordVisibility = () => {
        setIsOldPasswordVisible(!isOldPasswordVisible);
    };

    const toggleNewPassVisibility = () => {
        setIsNewPassVisible(!isNewPassVisible);
    };

    
    

    // const handleHighContrastToggle = () => {
    //     setHighContrastMode((prevMode) => !prevMode);
    // };

    // const handleSubscriptionChange = (event) => {
    //     setSubscriptionFrequency(event.target.value);
    // };

    // const handleToggleNotifications = () => {
    //     setOptedOut((optedOut) => !optedOut);
    //     setShowPopup(true);
    //     setPopupMessage(
    //         optedOut
    //             ? 'You have resubscribed to notifications.'
    //             : 'You have successfully opted out of all notifications. To subscribe again, please click on the Opt in Notifications button.'
    //     );
    // };

    const handleSignOut = async () => {
        try {
          console.log('here at signout area!')
          console.log(useUser)
          await signOut();
          setIsSignedIn(false);
          navigate('/');
        } catch (error) {
          console.log('Error signing out: ', error);
        }
      };

        //alert messages
    const showError = (errorMessage) => {
      // const errorMessage = typeof error === 'string' ? error : 'Please Login To Continue.';
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    const showSuccess= (successMessage) => {
      // const errorMessage = typeof error === 'string' ? error : 'Please Login To Continue.';
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    async function deleteUserFromCognito(sub) {
        // try {
        //     const params = {
        //         UserPoolId: 'us-west-1_fLfL5QxTl',
        //         Username: sub, 
        //       };
              
        //   const command = new AdminDeleteUserCommand(params);
        //   const response = await cognito.send(command);
        // } catch (err) {
        //   console.error('Error deleting user:', err);
        // }
      }

      async function handleUpdatePassword(oldPassword, newPassword) {
        try {
          await updatePassword({ oldPassword, newPassword });
          showSuccess('Password Changed Successfully!')
        } catch (err) {
          console.log(err);
          if(err == 'NotAuthorizedException: Incorrect username or password.'){
            showError('NotAuthorizedException: Incorrect username or password.');
          }
          if(err == 'InvalidPasswordException: Password did not conform with policy: Password not long enough'){
            showError('InvalidPasswordException: Password did not conform with policy: Password not long enough');
          }
          if(err == 'LimitExceededException: Attempt limit exceeded, please try after some time.'){
            showError('LimitExceededException: Attempt limit exceeded, please try after some time.')
          }
          else{
            showError(err);
          }
        }
      }

      
    
      useEffect(() => {
        const fetchData = async () => {
          const user = await getCurrentUser();
        };
        
        fetchData();
      }, []);
      
      //deletes a user in cognito DOES NOT DELETE USER ATTRIBUTES FROM DYNAMO DB
      async function handleDeleteUser() {
        try {
          const isConfirmed = window.confirm("Are you sure you want to delete your account?");
          if (isConfirmed) {
            await deleteUser();
            showSuccess("Your account has successfully been deleted.");
            await handleSignOut();
            // navigate('/');
          }
          else {
            showSuccess("Account deletion canceled.");
          }
        } catch (error) {
          console.log("There was an error deleting your account: ", error);
        }
      }

      // const handleDeleteAccount = async () => {
      //   try {

      //     const isConfirmed = window.confirm("Are you sure you want to delete your account?");
      
      //     if (isConfirmed) {
      //       const user = await getCurrentUser();
      //       // const userData = await client.graphql({
      //       //   query: getUserByEmail,
      //       //   variables: {
      //       //     email: user.signInDetails.loginId
      //       //   }
      //       // });
      
      //       deleteUserFromCognito(user.userId);
      //       // await client.graphql({
      //       //   query: deleteUser,
      //       //   variables: {
      //       //     input: { id: userData.data.getUserByEmail.items[0].id }
      //       //   }
      //       // });
      
      //       handleSignOut();
      //       alert("Your account has successfully been deleted.");
      //     } else {
      //       alert("Account deletion canceled.");
      //     }

      //   } catch (err) {
      //     console.log("There was an error deleting your account: ", err);
      //   }
        
      // };
      

    return (
            <div className="w-full min-h-screen pt-12 mb-4">
                <div className="w-4/5 mx-auto mb-4">
                    <h3 className="text-3xl font-bold text-left">Account Settings:</h3>
                </div>

                {/* <div className="w-4/5 mx-auto mb-4 shadow-lg rounded-2xl">
                    <h3 className="mt-4 text-lg lg:text-2xl font-semibold text-left p-4">Subscription Preferences:</h3> */}

                    {/* <div className='px-6 text-md lg:text-xl'>
                        <label htmlFor="subscriptionFrequency">Select Frequency:</label>
                        <select
                            id="subscriptionFrequency"
                            className={`md:ml-2 p-2 text-md lg:text-xl ${optedOut ? 'bg-gray-200' : ''}`}
                            value={subscriptionFrequency}
                            onChange={(e) => handleSubscriptionChange}
                            disabled={optedOut}
                        >
                            <option value="monthly">Every Month (Default)</option>
                            <option value="biweekly">Every 2 Weeks</option>
                            <option value="weekly">Every Week</option>
                        </select>
                    </div> */}

                    {/* <div className="pl-6 pt-8">
                        <p className="text-md lg:text-xl">
                            {optedOut
                                ? 'Currently not receiving any notifications'
                                : 'I would like to opt out of all notifications'}
                        </p>
                        <button
                            className={`mt-2 mb-4 ${optedOut ? 'bg-green-500' : 'bg-red-500'
                                } text-white p-2 rounded-2xl text-md lg:text-xl`}
                            onClick={handleToggleNotifications}
                        >
                            {optedOut ? 'Opt in to notifications' : 'Opt out of notifications'}
                        </button>
                    </div> */}
                    {/* {showPopup && (
                        <Popup message={popupMessage} onClose={handleClosePopup} />
                    )} */}
                {/* </div> */}
                <div className="w-4/5 mx-auto mb-4 shadow-lg rounded-2xl">
                    <h3 className="text-lg lg:text-2xl font-bold text-left p-4">Update Password:</h3>
                    <div className="pb-4 px-6">
                        <p className="text-md lg:text-xl">
                            Please enter your old and new password.
                        </p>
                        {/* <button className="mt-2 mb-4 bg-red-500 text-white p-2 rounded-2xl text-md lg:text-xl" onClick={handleDeleteAccount}>
                            Update Password
                        </button> */}
                        <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 pl-4 items-center">
                          <label htmlFor="password" className="w-full text-left font-medium text-base text-gray-800 mb-2">Old Password</label>
                          <div className="relative w-full">
                              <input
                                  type={isOldPasswordVisible ? 'text' : 'password'}
                                  id="oldpassword"
                                  name="oldpassword"
                                  className="block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" // Added pr-10 for padding on the right
                                  placeholder="Old Password"
                                  required
                                  value={oldPassword}
                                  onChange={event => setOldPassword(event.target.value)}
                              />
                              <button onClick={toggleOldPasswordVisibility} className="absolute right-2 top-1/2 transform -translate-y-1/3">
                                  <img src={isOldPasswordVisible ? NoVis : Vis} alt="Visibility Toggle" className="w-6 h-6" />
                              </button>
                          </div>
                      </div>

                      <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 pl-4 items-center">
                          <label htmlFor="newPass" className="w-full text-left font-medium text-base text-gray-800 mb-2">New Password</label>
                          <div className="relative w-full">
                              <input
                                  type={isNewPassVisible ? 'text' : 'password'}
                                  id="newPass"
                                  name="newPass"
                                  className="block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" // Added pr-10 for padding on the right
                                  placeholder="New Password"
                                  required
                                  value={newPassword}
                                  onChange={event => setNewPassword(event.target.value)}
                              />
                              <button onClick={toggleNewPassVisibility} className="absolute right-2 top-1/2 transform -translate-y-1/3">
                                  <img src={isNewPassVisible ? NoVis : Vis} alt="Visibility Toggle" className="w-6 h-6" />
                              </button>
                          </div>
                      </div>

                      <button className="mt-2 mb-4 bg-red-500 text-white p-2 rounded-2xl text-md lg:text-xl" onClick={(e) => handleUpdatePassword(oldPassword, newPassword)}>
                            Change Password
                        </button>
                    </div>
                </div>
                <div className="w-4/5 mx-auto mb-4 shadow-lg rounded-2xl">
                    <h3 className="text-lg lg:text-2xl font-bold text-left p-4">Privacy:</h3>
                    <div className="pb-4 px-6">
                        <p className="text-md lg:text-xl">
                            I would like to delete my account and all information associated with it.
                        </p>
                        <button className="mt-2 mb-4 bg-red-500 text-white p-2 rounded-2xl text-md lg:text-xl" onClick={(e) => handleDeleteUser()}>
                            Delete Account
                        </button>
                    </div>
                </div>
            </div>
    );
};

export default EditSettings;
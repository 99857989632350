import React, { useEffect, useState } from 'react';
import NavBar from '../Common/Components/Navbar';
import EditInfo from './Components/EditInfo';
import EditSettings from './Components/EditSettings';
import Trials from './Components/SavedTrials';
import { getUserByEmail } from 'src/graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { getUser } from 'src/graphql/queries';
import Edit from './Components/EditInfo3';


const client = generateClient();


const UserInfo = () => {
  const [activeSection, setActiveSection] = useState('info');

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="bg-[#f5f4f4] min-h-screen">
      <NavBar />
      <div className="w-full flex">
        <div className="w-1/5 bg-[#dddddd]">
          <div className="w-full flex flex-col items-center">
            <button
              className={`mt-12 w-4/5 px-4 md:px-8 py-4 font-medium text-sm md:text-md lg:text-lg xl:text-xl rounded-2xl ${
                activeSection === 'info' ? 'bg-[#2b2138] text-white' : 'hover:bg-[#2b2138] hover:text-white'
              }`}
              onClick={() => handleButtonClick('info')}
            >
              Patient Info
            </button>
            <button
              className={`mt-4 w-4/5 px-4 md:px-8 py-4 font-medium text-sm md:text-md lg:text-lg xl:text-xl rounded-2xl ${
                activeSection === 'settings' ? 'bg-[#2b2138] text-white' : 'hover:bg-[#2b2138] hover:text-white'
              }`}
              onClick={() => handleButtonClick('settings')}
            >
              Account Settings
            </button>
            <button
              className={`mt-4 w-4/5 px-4 md:px-8 py-4 font-medium text-sm md:text-md lg:text-lg xl:text-xl rounded-2xl ${
                activeSection === 'trials' ? 'bg-[#2b2138] text-white' : 'hover:bg-[#2b2138] hover:text-white'
              }`}
              onClick={() => handleButtonClick('trials')}
            >
              Saved Trials
            </button>
          </div>
        </div>
        <div className="w-4/5 overflow-y-auto">
          {activeSection === 'info' && <Edit />}
          {activeSection === 'settings' && <EditSettings />}
          {activeSection === 'trials' && <Trials />}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;

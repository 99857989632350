import { useState } from 'react';
import { confirmSignUp } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import NavBar from 'src/Common/Components/Navbar';
function ValidatePage() {

  const naviagte = useNavigate();
  const [email, setEmail] = useState('');
  const [authenticationCode, setAuthenticationCode] = useState('');

  async function handleSignUpConfirmation(event) {
    event.preventDefault();

    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: email,
        confirmationCode: authenticationCode
      });

      naviagte('/signin')
    } catch (error) {
      console.log('Error confirming sign up', error);
    }
  }

  return (
    <div className="w-full">

      <NavBar />

      <div className="shadow-lg border-2 w-[26rem] lg:w-[48rem] flex flex-col lg:flex-row rounded-2xl mt-12 p-8 m-4 mx-auto justify-center">

        <div className="w-full lg:w-[19.25rem] mr-4">
          <div className="flex flex-row items-center">
            <img className="w-[5rem]" src={require('./Assets/Mail.png')} />
            <h3 className="ml-2 font-medium text-lg">Verify your email address</h3>
          </div>
          <h5 className="mt-2 lg:mt-6 text-base">A verification code has been sent to your email. Please check your inbox and enter your email address and verification code to continue.</h5>
        </div>

        <div className="w-[22rem] mt-6 lg:mt-0 lg:ml-6">
          <div className="w-[22rem] mx-auto flex flex-col items-center ">
            <label htmlFor="email" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" placeholder="Your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="w-[22rem] mx-auto flex flex-col mt-6 items-center">
            <label htmlFor="confirmation" className="w-full text-left font-medium text-base lg:text-lg text-gray-800 mb-2">Confirmation Code</label>
            <input
              type="confirmation"
              id="confirmation"
              name="confirmation"
              className="block py-2.5 px-4 w-full text-base lg:text-lg text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600" placeholder="Your email"
              required
              value={authenticationCode}
              onChange={(e) => setAuthenticationCode(e.target.value)}
            />
          </div>

          <div className="w-[22rem] mx-auto flex flex-col items-end">
            <button
              type="submit"
              className="w-[5rem] bg-orange-500 text-white py-2 rounded-2xl mt-4 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              onClick={handleSignUpConfirmation}
            >
              Submit
            </button>
          </div>
        </div>
      </div>



      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <form onSubmit={handleSignUpConfirmation} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Username" required style={{ marginBottom: '10px', padding: '8px', width: '200px' }} />
          <input type="text" value={authenticationCode} onChange={(e) => setAuthenticationCode(e.target.value)} placeholder="Confirmation Code" required style={{ marginBottom: '10px', padding: '8px', width: '200px' }} />
          <button type="submit" style={{ padding: '8px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Submit</button>
        </form>
      </div> */}
    </div>
  );
}

export default ValidatePage;

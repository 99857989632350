import { useState, useEffect, useRef } from 'react';
import { getCurrentUser } from "aws-amplify/auth"
import { getUserByEmail } from 'src/graphql/queries';
import { getUser } from 'src/graphql/queries';
import { UserProvider, useUser } from 'src/UserContext';
import { updateUser } from 'src/graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import amplifyconfig from 'src/amplifyconfiguration.json';
import { getClinicalTrialsList } from 'src/graphql/queries';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const client = generateClient();
Amplify.configure(amplifyconfig);
toast.configure();

const Trial = ({ Identifier, Name, Status, projAims, leadInves, Location, Ages, addCrit, Contacts, Link, distanceInfo }) => {
    const [isFavorited, setIsFavorited] = useState(false);
    const [showAddedPopup, setShowAddedPopup] = useState(false);
    const [showRemovedPopup, setShowRemovedPopup] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const contentRef = useRef(null);
    const [userDetails, setUserDetails] = useState(null);
    const [bookmarkedTrials, setBookmarkedTrials] = useState([]);
    const [test, setTest] = useState(null);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    const { isSignedIn, setIsSignedIn } = useUser();


    const showError = (error) => {
        const errorMessage = typeof error === 'string' ? error : 'Please Login To Continue.';
        toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };


    async function fetchUserById() {
        try {
            const user = await getCurrentUser();
            const userData = await client.graphql({
                query: getUser,
                variables: {
                    id: user.username
                }
            })
            return userData.data.getUser;
        } catch (error) {
            console.log('Error fetching user information by ID:', error);
            return null;

        }
    }

    async function isBookmarked() {
        const userData = await fetchUserById();

        function checkIdentifier(bookmarkedTrial) {
            return bookmarkedTrial == Identifier
        }

        if (userData.bookmarkedTrials) {
            if (userData.bookmarkedTrials.find(checkIdentifier) != undefined) {
                setIsFavorited(true);
            }
            else {
                setIsFavorited(false);
            }
        }

        else {
            setIsFavorited(false);
        }

    }

    async function updateUserSavedTrials(userId, userData, identifier, isRemove) {
        try {

            if (isRemove == false) {
                const updatedUserData = await client.graphql({
                    query: updateUser,
                    variables: {
                        input: {
                            id: userId,
                            bookmarkedTrials: [...userData.bookmarkedTrials, identifier],
                        },
                    },
                });

                return updatedUserData.data.updateUser;
            }

            else {

                const updatedBookmarkedTrials = userData.bookmarkedTrials.filter(item => item !== identifier);
                const updatedUserData = await client.graphql({
                    query: updateUser,
                    variables: {
                        input: {
                            id: userId,
                            bookmarkedTrials: updatedBookmarkedTrials,
                        },
                    },
                });

                return updatedUserData.data.updateUser;

            }
        } catch (error) {
            console.log('Error updating user information:', error);
            return null;

        }
    }


    async function updateBookmarkedTrials(identifier, isRemove) {
        try {
            const userData = await fetchUserById();
            console.log(userData)
            if (!Array.isArray(userData.bookmarkedTrials)) {
                userData.bookmarkedTrials = [];
            }
            const userId = userData.id;
            const updatedUser = await updateUserSavedTrials(userId, userData, identifier, isRemove);
            setUserData(updatedUser);
            return updatedUser;
        } catch (error) {
            console.error('Error updating user:', error);
            showError(error)
            navigate("/signIn")
        }
    }

    const checkOverflow = () => {
        if (contentRef.current) {
            setIsOverflowed(contentRef.current.offsetHeight < contentRef.current.scrollHeight);
        }
    };

    useEffect(() => {
        checkOverflow();
        if (isSignedIn) {
            isBookmarked();
        }
        else {
            setIsFavorited(false);
        }
    }, []);

    useEffect(() => {
        checkOverflow();
    }, [projAims]);

    const handleToggleFavorite = async () => {
        if (isFavorited) {
            await updateBookmarkedTrials(Identifier, true)
            setIsFavorited(false);
            setShowRemovedPopup(true);
        } else {
            await updateBookmarkedTrials(Identifier, false)
            setIsFavorited(true);
            setShowAddedPopup(true);
            fetchUserById();
        }
    };


    const handleToggleView = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const addedTimeoutId = setTimeout(() => {
            setShowAddedPopup(false);
        }, 2000);

        const removedTimeoutId = setTimeout(() => {
            setShowRemovedPopup(false);
        }, 2000);

        return () => {
            clearTimeout(addedTimeoutId);
            clearTimeout(removedTimeoutId);
        };
    }, [showAddedPopup, showRemovedPopup]);

    const [currentLocationIndex, setCurrentLocationIndex] = useState(0);

    const handlePrevLocation = () => {
        setCurrentLocationIndex((prevIndex) => (prevIndex === 0 ? Location.length - 1 : prevIndex - 1));
    };

    const handleNextLocation = () => {
        setCurrentLocationIndex((prevIndex) => (prevIndex === Location.length - 1 ? 0 : prevIndex + 1));
    };

    const getOrdinalSuffix = (number) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = number % 100;
        return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };
    // console.log({ distanceInfo });

    return (
        <div className={`w-4/5 mt-4 bg-white p-4 rounded-2xl shadow-xl ${isExpanded ? 'expanded' : 'condensed'}`}>
            <div className="flex flex-row justify-between">
                <div className="mb-4">
                    <h3 className="text-gray-500 text-left">Name: </h3>
                    <h3 className="text-xl font-bold text-left">{Name}</h3>
                </div>
                <div className="mt-6 ml-2 flex-shrink-0">
                    <button onClick={handleToggleFavorite} className="focus:outline-none">
                        {isFavorited ? (
                            <img src={require('../Assets/Filled2.png')} alt="Star Yellow" className="w-5 h-6" />
                        ) : (
                            <img src={require('../Assets/Unfilled2.png')} alt="Star Empty" className="w-5 h-6" />
                        )}
                    </button>
                </div>
            </div>

            {showAddedPopup && (
                <div className="bg-green-500 text-white p-2 rounded-md absolute top-0 right-0 mt-2 mr-2">
                    Added to Saved Trials
                </div>
            )}

            {showRemovedPopup && (
                <div className="bg-red-500 text-white p-2 rounded-md absolute top-0 right-0 mt-2 mr-2">
                    Removed from Saved Trials
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-10 gap-y-4">
                <div className="">
                    <h3 className="text-gray-500  text-left">Identifier: </h3>
                    <h3 className="text-lg font-bold text-left">{Identifier}</h3>
                </div>
                <div className="">
                    <h3 className="text-gray-500 text-left">Status:</h3>
                    <h3 className={`text-lg font-bold ${Status === 'RECRUITING' ? 'text-green-500' : Status === 'NOT YET RECRUITING' ? 'text-yellow-500' : ''} text-left`}>
                        {Status}
                    </h3>
                </div>
                <div className="md:mb-4">
                    <h3 className="text-gray-500 text-left">Lead Investigator/Institution:</h3>
                    <h3 className="text-left">{leadInves}</h3>
                </div>

                <div className="md:mb-4">
                    <h3 className="text-gray-500 text-left">Ages:</h3>
                    <h3 className="text-left">{Ages}</h3>
                </div>

            </div>


            {isExpanded && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-10 gap-y-4">
                        <div className=" mb-2">
                            <h3 className="text-gray-500 text-left">Project Aims:</h3>
                            {/* <div
                                className={`text-left overflow-hidden ${isExpanded ? '' : 'line-clamp-4'}`}
                                ref={contentRef}
                            > */}
                            <div className="w-full h-[16rem] border-2 rounded-2xl p-2 overflow-y-auto">
                                {projAims}
                            </div>
                        </div>

                        {Location && Location.length > 0 && (
                            <div className="mb-2">
                                <h3 className="text-gray-500 text-left">Location(s):</h3>
                                <div className="w-full h-[16rem] border-2 rounded-2xl p-2 overflow-y-auto">
                                    {Location.map((location, index) => (
                                        <div className="mb-2 flex flex-col" key={index}>
                                            <p>{location.facility}</p>
                                            <div className="flex flex-row items-baseline">
                                                <img src="/LocationPin.svg" alt="Location Pin" className="w-3 h-3 mr-1" />
                                                <div className="flex flex-col">
                                                    <p>
                                                        {location.city && (
                                                            <span>{location.city}, </span>
                                                        )}
                                                        {location.state && (
                                                            <span>{location.state}, </span>
                                                        )}
                                                        {location.country && (
                                                            <span>{location.country} </span>
                                                        )}
                                                    </p>
                                                    {distanceInfo && distanceInfo.length > 0 && (
                                                        <p className='italic text-sm'>Approximately {distanceInfo[index]} miles away</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {Location && Location.length == 0 && (
                            <div className="mb-2">
                                <h3 className="text-gray-500 text-left">Location(s):</h3>
                                <div className="w-full h-[16rem] border-2 rounded-2xl p-2 overflow-y-auto">
                                    Location Information not Available
                                </div>
                            </div>
                        )}

                        <div className="mb-2">
                            <h3 className="text-gray-500 text-left">Additional Criteria for Inclusion:</h3>
                            <div className="w-full h-[16rem] border-2 rounded-2xl p-2 overflow-y-auto">
                                <ul className="list-disc">
                                    {addCrit.split('\n').map((item, index) => (
                                        item.includes('Inclusion Criteria:') || item.includes('Exclusion Criteria:') || item.trim() === '' ? (
                                            <h3 className="font-medium" key={index}>{item.trim()}</h3>
                                        ) : (
                                            <li className="ml-5" key={index}>{item.trim().startsWith('*') ? item.trim().substring(1) : item.trim()}</li>
                                        )
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="">
                            {Contacts && Contacts.length > 0 ? (
                                <div className="mb-2">
                                    <h3 className="text-gray-500 text-left">Contacts:</h3>
                                    <div className="w-full h-[16rem] border-2 rounded-2xl p-2 overflow-y-auto">
                                        {Contacts.map((contact, index) => (
                                            <div key={index} className='mb-2'>
                                                <p>{contact.name}</p>
                                                <p>Phone: {contact.phone} {contact.phoneExt && `Ext: ${contact.phoneExt}`}</p>
                                                <p>Email: {contact.email}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <p className="text-red-500">Contacts information not available</p>
                            )}
                        </div>
                    </div>
                    <div className="w-full mt-2 mb-2">
                        <h3 className="font-semibold text-left">For more information, please visit:
                            <br />
                            <a href={Link} className="text-blue-500 hover:text-blue-700 break-all">{Link}</a></h3>
                    </div>

                </>
            )
            }

            <button onClick={handleToggleView} className="flex mx-auto focus:outline-none toggle-button">
                {isExpanded ? (
                    <img src={require('../Assets/Collapse.png')} alt="Collapse" className="w-10 h-5" />
                ) : (
                    <img src={require('../Assets/Expand.png')} alt="Collapse" className="w-10 h-5" />
                )}
            </button>
        </div >
    );
};

export default Trial;
const Highlighted3 = () => {

    const name = 'JelikaLite - tPBM Treatment Trial Study';
    const projAims = 'To examine effectiveness of a novel, non-invasive, light therapy device designed to improve language and behavior of children with ASD. A therapeutic wearable device combines non-invasive brain stimulation with infrared light, sensors and an AI personalization platform. The trial will include children between ages 2-10 with moderate to severe diagnosis of autism.';
    const leadInves = 'JelikaLite';
    const locations = 'Brooklyn, NY';
    const ages = '2 to 10';
    const addCrit = 'Moderate to severe autism; Ability to attend clinical sessions in Brooklyn office, NY: having your child visit a doctor’s office for a period of approximately 30 minutes, twice a week, for a period of 10 weeks.';
    const contact = 'fill in this form to express interest in participation';


    return (
        <div className={`w-full mt-4 bg-white p-6 rounded-2xl shadow-xl flex flex-row`}>

            <div className="w-full">
                <div className="mb-2 flex flex-row items-center">
                <img src={require('../Assets/3.jpeg')}  alt="test" className="h-20 mr-4 " />
                    <h3 className="text-xl font-bold text-left">{name}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Project Aims:</h3>
                    <div>
                        {projAims}
                    </div>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Lead Investigator/Institution:</h3>
                    <h3 className="text-left">{leadInves}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Location(s):</h3>
                    <h3 className="text-left">{locations}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Contacts:</h3>
                    <h3 className="text-left">{contact}</h3>
                </div>


                <div className="mb-2">
                    <h3 className="font-semibold text-left">Ages:</h3>
                    <h3 className="text-left">{ages}</h3>
                </div>

                <div className="mb-2">
                    <h3 className="font-semibold text-left">Additional Criteria for Inclusion:</h3>
                    <h3 className="text-left">
                        {addCrit.split(';').map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                        ))}
                    </h3>
                </div>

                {/* 
            <div className="mt-2 mb-2">
                <h3 className="font-semibold text-left">For more information please visit: <a href={Link} className="text-blue-500 hover:text-blue-700">{Link}</a></h3>
            </div> */}
            </div>
        </div>
    );
}

export default Highlighted3;


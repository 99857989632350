import React, { useState, useEffect } from 'react';
import { getUser } from 'src/graphql/queries';
import { updateUser } from 'src/graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from "aws-amplify/auth"
import { fetchUserAttributes } from "aws-amplify/auth"
import PhoneInput from 'react-phone-input-2';
import '../../RegistryForm/Styles/Style.css'
import { comorbidityTypesOptions, comorbiditiesState, comorbidityTypesArray, comorbidityTypesStringReverse } from '../../RegistryForm/Diagnosis';
import { updatePassword } from 'aws-amplify/auth';

const client = generateClient()
const EditInfo = () => {
    // Retrieves user from Cognito
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        fetchUserSubFromCognito();
    }, []);

    async function fetchUserSubFromCognito() {
        try {
            const user = await fetchUserAttributes();
            const userData = await client.graphql({
                query: getUser,
                variables: {
                    id: user.sub
                }
            });

            setUserDetails(userData.data.getUser);
            return;

        } catch (error) {
            console.log('Error fetching user sub from auth:', error);

            return null;
        }
    }

    const [userData, setUserData] = useState({});
    const [patientsList, setPatientsList] = useState([]);

    useEffect(() => {
        if (userDetails) {
            setUserData({
                firstName: userDetails["firstName"] || '',
                lastName: userDetails["lastName"] || '',
                dob: userDetails["dob"] || '',
                email: userDetails["email"] || '',
                phoneNumber: userDetails["phoneNumber"] || '',
                streetAddress1: userDetails["streetAddress1"] || '',
                streetAddress2: userDetails["streetAddress2"] || '',
                cityStatePostal: userDetails["cityStatePostal"] || '',
                country: userDetails["country"] || '',
                isPatient: userDetails["isPatient"] || false,
                chosenComorbidities: userDetails["chosenComorbidities"],
            });
            //console.log("let's see", userData)
            // console.log("typeof", typeof userData.chosenComorbidities)
            setTempProfile({
                firstName: userDetails["firstName"] || '',
                lastName: userDetails["lastName"] || '',
                dob: userDetails["dob"] || '',
                email: userDetails["email"] || '',
                phoneNumber: userDetails["phoneNumber"] || '',
                streetAddress1: userDetails["streetAddress1"] || '',
                streetAddress2: userDetails["streetAddress2"] || '',
                cityStatePostal: userDetails["cityStatePostal"] || '',
                country: userDetails["country"] || '',
                isPatient: userDetails["isPatient"] || false,
                chosenComorbidities: userDetails["chosenComorbidities"],
            });
            setPatientsList([userDetails.patientIDList])
            setSelectedPatient(userDetails["isPatient"])
            //console.log("temp profile", tempProfile)
            //console.log('USER comorb state: ', userDetails["chosenComorbidities"]);
            //console.log('comorb state: ', comorbiditiesState);
        }
    }, [userDetails]);


    // Retrieves all accounts under user
    const [patientsInfoList, setPatientsInfoList] = useState([]);

    useEffect(() => {
        if (patientsList[0]) {
            try {
                const fetchData = async () => {
                    const userDetailsArray = await Promise.all(patientsList[0].map(async (patient) => {
                        const userData = await client.graphql({
                            query: getUser,
                            variables: {
                                id: patient
                            }
                        });
                        return userData.data.getUser;
                    }));
                    //console.log('userDetailsArray: ', userDetailsArray)
                    setPatientsInfoList(userDetailsArray);
                    //console.log("we did it", patientsInfoList)
                };
                fetchData();
            } catch (error) {
                console.log('Error fetching user sub from auth:', error);
            }
        }
    }, [patientsList]);

    // Determines what is shown initially 
    const [selectedUser, setSelectedUser] = useState('user');

    const handleUserClick = (userId) => {
        setSelectedUser(userId)
        console.log('here in toggle')
        console.log(userId);
        if(userId != 'user') {
            setSelectedPatient(true);
        }
        else {
            setSelectedPatient(false);
        }
    };

    const profile = selectedUser === 'user' ? userData : patientsInfoList.find(patient => patient.id === selectedUser);
    const [formState, setFormState] = useState(comorbiditiesState);
    const [tempProfile, setTempProfile] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        phoneNumber: '',
        streetAddress1: '',
        streetAddress2: '',
        cityStatePostal: '',
        country: '',
        isPatient: false,
        chosenComorbidities: comorbiditiesState,
    });

    useEffect(() => {
        setTempProfile({
            firstName: profile.firstName,
            lastName: profile.lastName,
            dob: profile.dob,
            email: profile.email,
            phoneNumber: profile.phoneNumber,
            streetAddress1: profile.streetAddress1,
            streetAddress2: profile.streetAddress2,
            cityStatePostal: profile.cityStatePostal,
            country: profile.country,
            isPatient: profile.isPatient,
            chosenComorbidities: profile.chosenComorbidities,
        });
        //setSelectedPatient(tempProfile.isPatient);
    }, [selectedUser]);


    const [isEditing, setIsEditing] = useState(false);

    async function editUserInfo(profileId, requests) {
        try {
            console.log('profileId: ', profileId);
            console.log('requests: ', requests);
            await client.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: profileId,
                        ...requests
                    },
                },
            });
           // console.log(requests)
            return;
        } catch (error) {
            console.log('profileId: ', profileId);
            console.log('requests: ', requests);
            console.log("There was an error updating user information from DB: ", error);
        }
    }

    const handleChange = (field, value) => {
        setTempProfile(prevTempProfile => ({
            ...prevTempProfile,
            [field]: value
        }));
        //console.log("changed temp", tempProfile)
    };

    const handleEditClick = () => {
        setIsEditing(prevState => !prevState);
    };

    const handleSaveClick = async () => {
        setIsEditing(false);
        try {
            let personId;
            if (selectedUser === 'user') {
                personId = userDetails.id;
            }
            else {
                personId = profile.id;
            }
            //console.log('profile ID: ', personId);
            await editUserInfo(personId, tempProfile); // Use profile.id to identify the profile being edited
        } catch (error) {
            console.error('Error updating user information:', error);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setTempProfile({
            firstName: profile.firstName,
            lastName: profile.lastName,
            dob: profile.dob,
            email: profile.email,
            phoneNumber: profile.phoneNumber,
            streetAddress1: profile.streetAddress1,
            streetAddress2: profile.streetAddress2,
            cityStatePostal: profile.cityStatePostal,
            country: profile.country,
            chosenComorbidities: profile.chosenComorbidities,
        });
    };

    const [isPersonalExpanded, setPersonalExpanded] = useState(true);

    const togglePersonalExpand = () => {
        setPersonalExpanded(!isPersonalExpanded);
    };

    const [isDiagnosisExpanded, setDiagnosisExpanded] = useState(true);

    const [isSelectedPatient, setSelectedPatient] = useState(true);

    const toggleDiagnosisExpand = () => {
        setDiagnosisExpanded(!isDiagnosisExpanded);
    };

    function isValidJson(jsonString) {
        try {
          JSON.parse(jsonString);
          return true;
        } catch (error) {
          return false;
        }
      }

    function convertToValidJson(inputString) {
        // Replace '=' with ':' for key-value pairs
        const jsonCompatibleString = inputString.replace(/=/g, ':');
        // console.log('jsonCompatibleString: ', jsonCompatibleString);
        
        // Surround keys with double quotes
        const quotedKeysString = jsonCompatibleString.replace(/(\w+)(?=\:)/g, '"$1"');
        // console.log('quotedKeysString: ', quotedKeysString);
        
        // Convert empty values to empty strings
        const jsonString = quotedKeysString.replace(/(\w+):(?=(\s|\,|\}))/g, '$1:""');

        // Convert "abc":, to "abc":[]
        const jsonString2 = jsonString.replace(/"(\w+)":(?=,)/g, '"$1":[]');
        
        // Ensure arrays are enclosed in square brackets if they contain elements
        const finalJsonString = jsonString2.replace(/\w+\=\[\]/g, '[]');
        
        // Parse the resulting string to ensure it's valid JSON
        try {
            return finalJsonString
        } catch (error) {
            console.error('Error converting to valid JSON:', error);
            return null;
        }
        }
   

    const handleComorbidityArrayChange = (comorbidityType, value, isChecked) => {

        setTempProfile(prevPatient => {
            // const updatedPatients = prevPatients;
            var comorbs = '';

            if (!isValidJson(prevPatient.chosenComorbidities[0])) {
                console.log('here')
                comorbs = convertToValidJson(prevPatient.chosenComorbidities[0]);
            }

            else {
                comorbs = prevPatient.chosenComorbidities[0];
            }
            // console.log('new: ', prevPatient.chosenComorbidities[0]);
            const updatedChosenComorbidities = JSON.parse(comorbs);
            //console.log('updatedChosenComorbidities: ', updatedChosenComorbidities);
            const updatedComorbidityType = [...updatedChosenComorbidities[comorbidityType]];
    
            if (isChecked) {
            updatedComorbidityType.push(value);
            } else {
            const index = updatedComorbidityType.indexOf(value);
            if (index !== -1) {
                updatedComorbidityType.splice(index, 1);
            }
            }
    
            updatedChosenComorbidities[comorbidityType] = updatedComorbidityType;
            //console.log('updatedComorbidityType UPDATED: ', updatedChosenComorbidities);
    
            return { ...prevPatient, chosenComorbidities: [JSON.stringify(updatedChosenComorbidities)]};
          });

        
    };


    


    return (
        <div className="w-full min-h-screen overflow-y-auto pb-4">
            <div className="flex flex-wrap w-full justify-center pb-4">
                <div className="w-4/5 flex flex-row justify-between items-center pt-12">
                    <h3 className="text-3xl font-bold text-left">Patient Info</h3>
                    {isEditing ? (
                        <div className="flex">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 rounded-2xl" onClick={handleSaveClick}>
                                Save
                            </button>
                            <button className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded-2xl" onClick={handleCancelClick}>
                                Cancel
                            </button>
                        </div>
                    ) : (
                        <button className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-2xl" onClick={handleEditClick}>
                            Edit
                        </button>
                    )}
                </div>
            </div>

            <div className="w-4/5 mx-auto flex flex-col">
                <div className="flex flex-row">
                    <button className={`w-32 rounded-2xl p-2 ${selectedUser === 'user' ? 'bg-gray-300' : ''}`}
                        onClick={() => handleUserClick('user')}>
                        <p>Your info</p>
                    </button>
                    {patientsInfoList.map(patient => (
                        <button key={patient.id} className={` w-32 rounded-2xl p-2 ${selectedUser === patient.id ? 'bg-gray-300' : ''}`}
                            onClick={() => handleUserClick(patient.id)}>
                            <p>{patient.firstName}</p>
                        </button>
                    ))}
                </div>

                <div className="w-full rounded-2xl flex flex-col mt-2 border-2 border-gray-300 shadow-xl ">
                    <div className={`flex flex-row justify-between items-center p-2  ${isPersonalExpanded ? 'rounded-t-2xl' : 'rounded-2xl'
                        }`}>
                        <h3 className="ml-2 font-semibold text-medium">Personal Information</h3>
                        <button className="ml-2" onClick={togglePersonalExpand}>
                            {isPersonalExpanded ? (
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.9875 8.7915C13.9025 8.7915 12.8505 9.1918 11.8852 9.87645L6.90824 13.3967C6.41819 13.7424 6.30354 14.4194 6.64954 14.9074C6.99484 15.3967 7.67152 15.5117 8.15989 15.1661L13.1351 11.6418C13.8314 11.1505 14.4255 10.9558 14.9915 10.9558C15.5575 10.9558 16.1531 11.1485 16.8628 11.6521L21.84 15.1758C22.3301 15.5218 23.0067 15.4055 23.3527 14.9178C23.698 14.4282 23.5815 13.7528 23.094 13.4075L18.116 9.88523C17.135 9.19188 16.0727 8.79158 14.9873 8.79158L14.9875 8.7915Z" fill="black" />
                                    <path d="M14.9875 14.6472C13.9025 14.6472 12.8505 15.0458 11.8852 15.7302V15.7319L6.90824 19.2529C6.41819 19.5986 6.30354 20.2755 6.64954 20.7632C6.99484 21.2529 7.67152 21.3692 8.15989 21.0219L13.1351 17.4999C13.8314 17.0066 14.4255 16.8122 14.9915 16.8122C15.5575 16.8122 16.1531 17.0049 16.8628 17.5079L21.84 21.0327C22.3301 21.3777 23.0067 21.2633 23.3527 20.774C23.698 20.2856 23.5815 19.6093 23.094 19.2636L18.116 15.7426C17.135 15.0483 16.0727 14.6473 14.9873 14.6473L14.9875 14.6472Z" fill="black" />
                                </svg>

                            ) : (
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0125 21.2085C16.0975 21.2085 17.1495 20.8082 18.1148 20.1235L23.0918 16.6033C23.5818 16.2576 23.6965 15.5806 23.3505 15.0926C23.0052 14.6033 22.3285 14.4883 21.8401 14.8339L16.8649 18.3582C16.1686 18.8495 15.5745 19.0442 15.0085 19.0442C14.4425 19.0442 13.8469 18.8515 13.1372 18.3479L8.15996 14.8242C7.66991 14.4782 6.99326 14.5945 6.64726 15.0822C6.30196 15.5718 6.41855 16.2472 6.90596 16.5925L11.884 20.1148C12.865 20.8081 13.9273 21.2084 15.0127 21.2084L15.0125 21.2085Z" fill="black" />
                                    <path d="M15.0125 15.3528C16.0975 15.3528 17.1495 14.9542 18.1148 14.2698V14.2681L23.0918 10.7471C23.5818 10.4014 23.6965 9.72447 23.3505 9.23677C23.0052 8.74712 22.3285 8.63082 21.8401 8.97807L16.8649 12.5001C16.1686 12.9934 15.5745 13.1878 15.0085 13.1878C14.4425 13.1878 13.8469 12.9951 13.1372 12.4921L8.15996 8.96732C7.66991 8.6223 6.99326 8.73666 6.64726 9.22602C6.30196 9.7144 6.41855 10.3907 6.90596 10.7364L11.884 14.2574C12.865 14.9517 13.9273 15.3527 15.0127 15.3527L15.0125 15.3528Z" fill="black" />
                                </svg>
                            )}
                        </button>
                    </div>
                    {isPersonalExpanded && (
                        <div className="p-4">
                            <div className="w-full mx-auto flex flex-col">
                                <div className="flex flex-col md:flex-row justify-between w-full mb-2">
                                    <div className="w-full md:w-1/2 mr-2 mb-4 md:mb-0">
                                        <label htmlFor="firstName" className="text-left font-medium text-base text-gray-800 mb-2">First Name</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                                }`}
                                            value={tempProfile.firstName}
                                            disabled={!isEditing}

                                            onChange={event => {
                                                const inputFN = event.target.value;
                                                const lettersFN = inputFN.replace(/[^a-zA-Z]/g, '');
                                                handleChange('firstName', lettersFN);
                                            }}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 md:ml-2">
                                        <label htmlFor="lastName" className="text-left font-medium text-base text-gray-800 mb-2">Last Name</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                                }`}
                                            value={tempProfile.lastName}
                                            disabled={!isEditing}
                                            onChange={event => {
                                                const inputLN = event.target.value;
                                                const lettersLN = inputLN.replace(/[^a-zA-Z]/g, '');
                                                handleChange('lastName', lettersLN);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mx-auto flex flex-col pt-4 items-center">
                                <label htmlFor="dob" className="w-full text-left font-medium text-base text-gray-800 mb-2">Date of Birth</label>
                                <input
                                    type="date"
                                    id="dob"
                                    className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                        }`}
                                    value={tempProfile.dob}
                                    disabled={!isEditing}
                                    onChange={event => handleChange('dob', event.target.value)}
                                />
                            </div>

                            <div className="w-full mx-auto flex flex-col pt-4 items-center">
                                <label htmlFor="email" className="w-full text-left font-medium text-base text-gray-800 mb-2">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                        }`} placeholder="Your email"
                                    value={tempProfile.email}
                                    disabled={!isEditing}
                                    onChange={event => handleChange('email', event.target.value)}
                                />
                            </div>

                            <div className="w-full mx-auto flex flex-col pt-4 items-center">
                                <label htmlFor="number" className="w-full text-left font-medium text-base text-gray-800 mb-2">Phone number</label>
                                <PhoneInput
                                    country={'us'}
                                    value={tempProfile.phoneNumber}
                                    disabled={!isEditing}
                                    onChange={event => handleChange('phoneNumber', event.toString())}
                                    className={`${isEditing ? 'bg-white' : 'bg-gray-100'}`}
                                    inputProps={{
                                        name: 'number',
                                        required: true,
                                        placeholder: 'Your phone number',
                                    }}
                                />
                            </div>

                            <div className="w-full mx-auto flex flex-col pt-4 items-center">
                                <label htmlFor="streetAddress1" className="w-full text-left font-medium text-base text-gray-800 mb-2">Street Address 1</label>
                                <input
                                    type="text"
                                    id="streetAddress1"
                                    className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                        }`}
                                    value={tempProfile.streetAddress1}
                                    disabled={!isEditing}
                                    onChange={event => handleChange('streetAddress1', event.target.value)}
                                />
                            </div>

                            <div className="w-full mx-auto flex flex-col pt-4 items-center">
                                <label htmlFor="streetAddress2" className="w-full text-left font-medium text-base text-gray-800 mb-2">Street Address 2</label>
                                <input
                                    type="text"
                                    id="streetAddress2"
                                    className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                        }`}
                                    value={tempProfile.streetAddress2}
                                    disabled={!isEditing}
                                    onChange={event => handleChange('streetAddress2', event.target.value)}
                                />
                            </div>

                            <div className="w-full mx-auto flex flex-col pt-4 items-center">
                                <label htmlFor="cityStatePostal" className="w-full text-left font-medium text-base text-gray-800 mb-2">City, Principle Subdivision (i.e. Province, State, County, etc.), Postal Code</label>
                                <input
                                    type="text"
                                    id="cityStatePostal"
                                    className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                        }`}
                                    value={tempProfile.cityStatePostal}
                                    disabled={!isEditing}
                                    onChange={event => handleChange('cityStatePostal', event.target.value)}
                                />
                            </div>

                            <div className="w-full mx-auto flex flex-col pt-4 items-center">
                                <label htmlFor="country" className="w-full text-left font-medium text-base text-gray-800 mb-2">Country</label>
                                <input
                                    type="text"
                                    id="country"
                                    className={`block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 rounded-2xl appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 ${isEditing ? 'bg-white' : 'bg-gray-100'
                                        }`}
                                    value={tempProfile.country}
                                    disabled={!isEditing}
                                    onChange={event => handleChange('country', event.target.value)}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {isSelectedPatient && (
                <div className="w-full rounded-2xl flex flex-col mt-2 border-2 border-gray-300 shadow-xl ">

                    <div className={`flex flex-row justify-between items-center p-2 ${isDiagnosisExpanded ? 'rounded-t-2xl' : 'rounded-2xl'
                        }`}>
                        <h3 className="ml-2 font-semibold text-medium">Diagnosis Information</h3>
                        <button className="ml-2" onClick={toggleDiagnosisExpand}>
                            {isDiagnosisExpanded ? (
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.9875 8.7915C13.9025 8.7915 12.8505 9.1918 11.8852 9.87645L6.90824 13.3967C6.41819 13.7424 6.30354 14.4194 6.64954 14.9074C6.99484 15.3967 7.67152 15.5117 8.15989 15.1661L13.1351 11.6418C13.8314 11.1505 14.4255 10.9558 14.9915 10.9558C15.5575 10.9558 16.1531 11.1485 16.8628 11.6521L21.84 15.1758C22.3301 15.5218 23.0067 15.4055 23.3527 14.9178C23.698 14.4282 23.5815 13.7528 23.094 13.4075L18.116 9.88523C17.135 9.19188 16.0727 8.79158 14.9873 8.79158L14.9875 8.7915Z" fill="black" />
                                    <path d="M14.9875 14.6472C13.9025 14.6472 12.8505 15.0458 11.8852 15.7302V15.7319L6.90824 19.2529C6.41819 19.5986 6.30354 20.2755 6.64954 20.7632C6.99484 21.2529 7.67152 21.3692 8.15989 21.0219L13.1351 17.4999C13.8314 17.0066 14.4255 16.8122 14.9915 16.8122C15.5575 16.8122 16.1531 17.0049 16.8628 17.5079L21.84 21.0327C22.3301 21.3777 23.0067 21.2633 23.3527 20.774C23.698 20.2856 23.5815 19.6093 23.094 19.2636L18.116 15.7426C17.135 15.0483 16.0727 14.6473 14.9873 14.6473L14.9875 14.6472Z" fill="black" />
                                </svg>

                            ) : (
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0125 21.2085C16.0975 21.2085 17.1495 20.8082 18.1148 20.1235L23.0918 16.6033C23.5818 16.2576 23.6965 15.5806 23.3505 15.0926C23.0052 14.6033 22.3285 14.4883 21.8401 14.8339L16.8649 18.3582C16.1686 18.8495 15.5745 19.0442 15.0085 19.0442C14.4425 19.0442 13.8469 18.8515 13.1372 18.3479L8.15996 14.8242C7.66991 14.4782 6.99326 14.5945 6.64726 15.0822C6.30196 15.5718 6.41855 16.2472 6.90596 16.5925L11.884 20.1148C12.865 20.8081 13.9273 21.2084 15.0127 21.2084L15.0125 21.2085Z" fill="black" />
                                    <path d="M15.0125 15.3528C16.0975 15.3528 17.1495 14.9542 18.1148 14.2698V14.2681L23.0918 10.7471C23.5818 10.4014 23.6965 9.72447 23.3505 9.23677C23.0052 8.74712 22.3285 8.63082 21.8401 8.97807L16.8649 12.5001C16.1686 12.9934 15.5745 13.1878 15.0085 13.1878C14.4425 13.1878 13.8469 12.9951 13.1372 12.4921L8.15996 8.96732C7.66991 8.6223 6.99326 8.73666 6.64726 9.22602C6.30196 9.7144 6.41855 10.3907 6.90596 10.7364L11.884 14.2574C12.865 14.9517 13.9273 15.3527 15.0127 15.3527L15.0125 15.3528Z" fill="black" />
                                </svg>

                            )}
                        </button>
                    </div>
                    
                    {isDiagnosisExpanded && tempProfile.chosenComorbidities && tempProfile.chosenComorbidities[0] && (
                        <div className="p-4">
                            {comorbidityTypesArray.map(type => (
                                <div key={type} className="w-full px-4 mx-auto flex flex-col mt-2 border-2 border-gray-300 rounded-2xl p-2 shadow-lg">
                                    <label className="font-normal text-lg text-gray-800">{type}</label>
                                    <div className="grid grid-cols-2 gap-2 items-center">
                                        {comorbidityTypesOptions[comorbidityTypesStringReverse[type]].map(option => {
                                            // Check if the option exists in the chosenComorbidities array
                                            var isChecked = JSON.parse(tempProfile.chosenComorbidities[0])[comorbidityTypesStringReverse[type]].includes(option);
                                            return (
                                                <label key={option} className="font-normal text-base text-gray-800">
                                                    <input
                                                        type="checkbox"
                                                        className='mr-2'
                                                        name={comorbidityTypesStringReverse[type]}
                                                        value={option}
                                                        id={comorbidityTypesStringReverse[type]+'-'+option}
                                                        // disabled={!isEditing}
                                                        onChange={event => {
                                                            handleComorbidityArrayChange(event.target.name, event.target.value, event.target.checked);
                                                        }
                                                        }
                                                        checked={isChecked}
                                                    
                                                    />
                                                    {option}
                                                </label>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                )}

            </div>

        </div >
    );
};


export default EditInfo;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClinicalTrialsList = /* GraphQL */ `
  mutation CreateClinicalTrialsList(
    $input: CreateClinicalTrialsListInput!
    $condition: ModelClinicalTrialsListConditionInput
  ) {
    createClinicalTrialsList(input: $input, condition: $condition) {
      nctId
      officialTitle
      briefSummary
      orgName
      status
      startDate
      completionDate
      maxAge
      minAge
      maxAgeFloat
      minAgeFloat
      sex
      eligibilityCriteria
      studyLink
      centralContacts {
        name
        phone
        email
        phoneExt
        __typename
      }
      locationArray {
        zip
        country
        state
        facility
        city
        __typename
      }
      isbrainfoundation
      lastUpdatePostDate
      studyFirstPostDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClinicalTrialsList = /* GraphQL */ `
  mutation UpdateClinicalTrialsList(
    $input: UpdateClinicalTrialsListInput!
    $condition: ModelClinicalTrialsListConditionInput
  ) {
    updateClinicalTrialsList(input: $input, condition: $condition) {
      nctId
      officialTitle
      briefSummary
      orgName
      status
      startDate
      completionDate
      maxAge
      minAge
      maxAgeFloat
      minAgeFloat
      sex
      eligibilityCriteria
      studyLink
      centralContacts {
        name
        phone
        email
        phoneExt
        __typename
      }
      locationArray {
        zip
        country
        state
        facility
        city
        __typename
      }
      isbrainfoundation
      lastUpdatePostDate
      studyFirstPostDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClinicalTrialsList = /* GraphQL */ `
  mutation DeleteClinicalTrialsList(
    $input: DeleteClinicalTrialsListInput!
    $condition: ModelClinicalTrialsListConditionInput
  ) {
    deleteClinicalTrialsList(input: $input, condition: $condition) {
      nctId
      officialTitle
      briefSummary
      orgName
      status
      startDate
      completionDate
      maxAge
      minAge
      maxAgeFloat
      minAgeFloat
      sex
      eligibilityCriteria
      studyLink
      centralContacts {
        name
        phone
        email
        phoneExt
        __typename
      }
      locationArray {
        zip
        country
        state
        facility
        city
        __typename
      }
      isbrainfoundation
      lastUpdatePostDate
      studyFirstPostDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      dob
      phoneNumber
      streetAddress1
      streetAddress2
      cityStatePostal
      country
      chosenComorbidities
      interests
      consent
      bookmarkedTrials
      isAccountHolder
      isPatient
      patientIDList
      accountHolderId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      dob
      phoneNumber
      streetAddress1
      streetAddress2
      cityStatePostal
      country
      chosenComorbidities
      interests
      consent
      bookmarkedTrials
      isAccountHolder
      isPatient
      patientIDList
      accountHolderId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      dob
      phoneNumber
      streetAddress1
      streetAddress2
      cityStatePostal
      country
      chosenComorbidities
      interests
      consent
      bookmarkedTrials
      isAccountHolder
      isPatient
      patientIDList
      accountHolderId
      createdAt
      updatedAt
      __typename
    }
  }
`;

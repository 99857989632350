import React, { useState, useEffect } from 'react';
import Trial from "../../AvailTrials/Components/Trial";
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from "aws-amplify/auth";
import { getUser } from 'src/graphql/queries';
import { getUserByEmail } from 'src/graphql/queries';
import { getClinicalTrialsList } from 'src/graphql/queries';

const client = generateClient()

const Trials = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [userData, setUserData] = useState('')
    const trialsPerPage = 10;
    const [bookmarkedTrials, setBookmarkedTrials] = useState([]);    
    const [content, setContent] = useState([]);



    async function fetchUserById() {
      try {
        const user = await getCurrentUser();

        const userData = await client.graphql({
          query: getUser,
          variables: {
            id : user.username
          }
        });

        return userData;
      } catch (error) {
        console.log('Error fetching user information by ID:', error);
        return null;

      }
    } 

    async function fetchTrialByNctIds(userData) {
        try {
            let bookmarkedTrials = []
            for (let i = 0; i < userData.bookmarkedTrials.length; i++) {
                const info = await client.graphql({
                    query: getClinicalTrialsList,
                    variables: {
                        nctId: userData.bookmarkedTrials[i]
                    }
                })
                if (info != null) {
                  bookmarkedTrials.push(info) ;
                }
              }     
            // print('bookmarkedTrials: ', bookmarkedTrials)
            setBookmarkedTrials(bookmarkedTrials);
            console.log('bookmarkedTrials: ', bookmarkedTrials)
            return;
        } catch (error) {
            console.error('Error getting bookmarked trials:', error);
            return null;
        }
    }
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const userData = await fetchUserById();
            if (userData) {
              fetchTrialByNctIds(userData.data.getUser);
            }
          } catch (error) {
            console.error('Error fetching user information:', error);
          }
        };
        fetchData();
      }, []);

    const indexOfLastTrial = currentPage * trialsPerPage;
    const indexOfFirstTrial = indexOfLastTrial - trialsPerPage;
    const currentTrials = bookmarkedTrials.slice(indexOfFirstTrial, indexOfLastTrial);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        console.log("scrolling");
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(bookmarkedTrials.length / bookmarkedTrials); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="w-full min-h-screen">
            <div className="w-full mb-4 pt-12">
                <div className="w-4/5 mx-auto mb-4">
                    <h3 className="text-3xl font-bold text-left">Saved Trials:</h3>
                    <h3 className="">
                        Now viewing {indexOfFirstTrial + 1}-{Math.min(indexOfLastTrial, bookmarkedTrials.length)} out of {bookmarkedTrials.length} trials
                    </h3>
                </div>
            </div>
            <div className="flex flex-col items-center mb-10">
            {bookmarkedTrials && bookmarkedTrials.map((trial, index) => (
                <Trial
                  key={index}
                  Identifier={trial.data.getClinicalTrialsList.nctId}
                  Name={trial.data.getClinicalTrialsList.officialTitle}
                  Status={trial.data.getClinicalTrialsList.status === 'NOT_YET_RECRUITING' ? 'NOT YET RECRUITING' : trial.data.getClinicalTrialsList.status}
                  projAims={trial.data.getClinicalTrialsList.briefSummary}
                  leadInves={trial.data.getClinicalTrialsList.orgName}
                  Location={trial.data.getClinicalTrialsList.locationArray || []}
                  Ages={`${trial.data.getClinicalTrialsList.minAge} - ${trial.data.getClinicalTrialsList.maxAge}`}
                  addCrit={trial.data.getClinicalTrialsList.eligibilityCriteria}
                  Contacts={trial.data.getClinicalTrialsList.centralContacts || []}
                  Link={trial.data.getClinicalTrialsList.studyLink}
                  distanceInfo={trial.data.getClinicalTrialsList.locationDistances}
                />
              ))}
            </div>
            <div className="flex justify-center mb-4">
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`mx-2 p-2 border rounded-xl ${currentPage === number ? 'font-bold text-lg bg-stone-200' : ''}`}
                    >
                        {number}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Trials;

import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from "./Components/Navbar";
import BG from './Assets/Blue.png';
import BG2 from './Assets/Blue5.png';
import PersonalForm from '../RegistryForm/RegistryPersonal'
import Form from '../RegistryForm/Registry2.js';
import Banner from '../Synchrony/Banner';



const Home = () => {
    const navigate = useNavigate();

    // Whenever someone clicks "Sign Up Now", page will scroll to form
    const [signupScroll, setSignupScroll] = useState(false);

    useLayoutEffect(() => {
        if (signupScroll) {
            const targetSection = document.getElementById('signupSection');
            if (targetSection) {
                targetSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
                setSignupScroll(false);
            }
        }
    }, [signupScroll]);

    // State for type of user, defaults to personal 
    const [form, setForm] = useState('PersonalForm');

    const [formScroll, setFormScroll] = useState(false);

    // Switches between personal and guardian form
    const handleFormType = (formType) => {
        setForm(formType);
        setFormScroll(true);
    };

    useLayoutEffect(() => {
        if (formScroll) {
            const targetSection = document.getElementById('formSection');
            if (targetSection) {
                targetSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
                setFormScroll(false);
            }
        }
    }, [formScroll]);

    return (
        <div className="relative flex flex-col">
            <NavBar />
            {/* <Banner /> */}
            <div className="w-full bg-[#f5f4f4]">
                <div className="flex items-center relative h-[26rem] sm:h-[30rem] lg:h-[40rem] 3xl:h-[50rem]">
                    <img
                        src={BG}
                        alt="BG"
                        className="w-full h-full object-cover hidden lg:block"
                    />
                    <img
                        src={BG2}
                        alt="BG"
                        className="w-full h-full object-cover hidden md:block lg:hidden"
                    />
                    <div className="bg-[#dee5f2] w-full h-full absolute top-0 left-0 md:hidden"></div>

                    <div className="absolute flex flex-col items-center p-6 gap-6 2xl:gap-12 3xl:gap-16 w-full h-fit xs:p-16 md:w-[32rem] lg:w-[36rem] xl:w-[40rem] xl:left-20 2xl:w-[45rem] 3xl:w-[50rem] 3xl:left-72">
                        <h3 className=" text-sm xs:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-semibold lg:text-lg xl:text-xl text-black">Our mission is to support translational research that will lead to the development of FDA-approved treatments and an improved standard of care for co-morbidities in individuals with autism.
                            <br />
                            <br />
                            We invite you to be a part of this important mission by registering and participating in clinical trials. Your involvement will make a significant difference in the lives of patients worldwide.
                        </h3>

                        <div className="flex space-x-4 mb-4">
                            <button className="w-[11.25rem] xs:w-[13rem] md:w-[11.5rem] lg:w-[13.5rem] 2xl:w-[16rem] bg-[#2b2138] text-white text-sm xs:text-base xl:text-lg 2xl:text-xl font-semibold rounded-full hover:bg-[#005077] text-base px-2 py-3 2xl:py-4 " onClick={() => setSignupScroll(true)}>Sign Up Now</button>
                            <button className="w-[11.25rem] xs:w-[13rem] md:w-[11.5rem] lg:w-[13.5rem] 2xl:w-[16rem] bg-[#2b2138] text-white text-sm xs:text-base xl:text-lg 2xl:text-xl font-semibold rounded-full hover:bg-[#005077] text-base px-2 py-3 2xl:py-4 " onClick={() => navigate('/explore')}> Find Current Trials</button>
                        </div>
                    </div>
                </div>

                <div className="max-w-[120rem] mx-auto">
                    <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-6 2xl:pt-12 items-center">
                        <div className="w-full flex flex-col justify-center" id="signupSection">
                            <h3 className="text-3xl 2xl:text-4xl text-center font-semibold text-[#005077]">Registry Intake Form for Clinical Trials</h3>
                            <h3 className="mt-4 2xl:mt-8 text-base xs:text-lg xl:text-xl 2xl:text-2xl text-left">By filling out this form, you are creating an account for The Brain Registry. With this account, you can browse trials, bookmark them, and update your information at any time.</h3>

                            <div className="w-full mt-8 flex flex-col">
                                <h3 className="text-2xl text-left font-semibold underline underline-offset-8 text-[#005077] underline-[#005077]">Step 1: Who is this account for?</h3>
                                <div className="w-full mt-6 xl:mt-8 ">
                                    <div className="w-fit flex flex-col xl:flex-row xl:gap-12 2xl:gap-24 3xl:gap-28 items-center mx-auto">
                                        <button
                                            className="w-full xl:w-[22.5rem] 2xl:w-[25.5rem] 3xl:w-[32.5rem] xs:h-20 bg-[#2b2138] text-white rounded-2xl hover:bg-[#005077] text-sm xs:text-base xl:text-lg 2xl:text-xl font-semibold px-6 py-3 mb-4 xl:mb-0"
                                            onClick={() => handleFormType('PersonalForm')}

                                        >
                                            I am an adult interested in participating in clinical trials
                                        </button>
                                        <button
                                            className="w-full xl:w-[22.5rem] 2xl:w-[25.5rem] 3xl:w-[32.5rem] xs:h-20 bg-[#2b2138] text-white rounded-2xl hover:bg-[#005077] text-sm xs:text-base xl:text-lg 2xl:text-xl font-semibold px-6 py-3 xl:mb-0"
                                            onClick={() => handleFormType('Form')}

                                        >
                                            I am a parent, guardian, or caretaker exploring clinical trials for a child
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full mx-auto" id="formSection">
                    {form === 'Form' && <Form />}
                    {form === 'PersonalForm' && <PersonalForm />}
                </div>

            </div>
        </div>
    );
}

export default Home;
import React, { useRef, useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import PhoneInput from 'react-phone-input-2';
import './Styles/Style.css'
import { createUser } from '../graphql/mutations';
import { signUp } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import NoVis from './Assets/NoVis.svg';
import Vis from './Assets/Vis.svg';
import { toast } from 'react-toastify';
import { comorbidityTypesString, comorbidityTypesOptions, comorbiditiesState, comorbidityTypesArray, comorbidityTypesStringReverse } from './Diagnosis.js';
import { createCaregiverAndPatients2 } from '../graphql/mutations';

const client = generateClient();
toast.configure();


const Registry = () => {

    //alert messages
    const showError = (errorMessage) => {
        // const errorMessage = typeof error === 'string' ? error : 'Please Login To Continue.';
        toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    // variables for navigation and captcha
    const navigate = useNavigate();
    const recaptcha = useRef();


    // State variables to store caregiver details
    const [caregiverData, setCaregiverData] = useState({
        id: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        sex: '',
        gender: '',
        ethnicity: '',
        email: '',
        phoneNumber: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        chosenComorbidities: comorbiditiesState,
        interests: '',
        isAccountHolder: true,
        isPatient: true,
        patientIDList: [],
        accountHolderId: '',
        consent: false,
        relationship: '',
    });

    // State variable to store patients' details
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [consents, setConsents] = useState(false);

    // Set comorbidities

    const [formState, setFormState] = useState(comorbiditiesState);

    const [comorbState, setComorbState] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Call GraphQL mutation to add caregiver and patients
        try {
            // Check if consent is signed/chosen
            if (consents) {
                caregiverData['consent'] = true;
                navigate('/validatePage');

                // Create user account in cognito
                const userSubInfo = await signUp({
                    username: caregiverData['email'],
                    email: caregiverData['email'],
                    password,
                });

                caregiverData['id'] = userSubInfo.userId;
                caregiverData['accountHolderId'] = userSubInfo.userId;
                caregiverData['chosenComorbidities'] = JSON.stringify(caregiverData['chosenComorbidities']);

                // Create account holder entry in DynamoDB


                // Create account holder entry in DynamoDB
                const { data } = await client.graphql({
                    query: createUser,
                    variables: {
                        input: caregiverData
                    }
                });


                // Clear input fields after submission
                setCaregiverData({
                    ...caregiverData,
                    id: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    dob: '',
                    email: '',
                    sex: '',
                    gender: '',
                    ethnicity: '',
                    phoneNumber: '',
                    streetAddress1: '',
                    streetAddress2: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    country: '',
                    chosenComorbidities: comorbiditiesState,
                    interests: '',
                    isAccountHolder: true,
                    isPatient: true,
                    patientIDList: [],
                    accountHolderId: '',
                    consent: false,
                    relationship: '',
                });
            }

            else {
                showError('Please check all consent boxes!')
            }
        } catch (error) {
            console.error('Error adding caregiver and patients:', error);
        }
    };


    //Handler function to update consent checkboxes
    const handleCheckboxChange = (e) => {
        setConsents(e.target.checked);
    };

    const [comorValues, setComorValues] = useState(comorbiditiesState);

    const handleComorbidities = (name, value, isChecked) => {
        setComorValues(prevState => {
            const updatedComorValues = { ...prevState };
            //console.log('comorbvalue: ', updatedComorValues)
            const updatedComorbType = [...updatedComorValues[name]]

            if (isChecked) {
                updatedComorbType.push(value);
            } else {
                const index = updatedComorbType.indexOf(value);
                if (index !== -1) {
                    updatedComorbType.splice(index, 1);
                }
            }
            updatedComorValues[name] = updatedComorbType;
            handleCaregiverChange('chosenComorbidities', updatedComorValues);

            // Return the updated value for comorValues
            return updatedComorValues;
        });
    };

    // Now, when you log the caregiverData, it should reflect the updated state
    //console.log(caregiverData);


    // Handler function to update caregiver's data
    const handleCaregiverChange = (name, value) => {
        // const { name, value } = event.target;
        setCaregiverData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    //UI RELATED COMPONENTS
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const toggleConfirmPassVisibility = () => {
        setIsConfirmPassVisible(!isConfirmPassVisible);
    };

    const [isPersonalVisible, setIsPersonalVisible] = useState(true);
    const [isPersonalCompleted, setIsPersonalCompleted] = useState(false);
    const [isDiagCompleted, setIsDiagCompleted] = useState(false);

    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // Splitting comorbidityTypesArray into two parts
    const firstPart = comorbidityTypesArray.slice(0, Math.ceil(comorbidityTypesArray.length / 2));
    const secondPart = comorbidityTypesArray.slice(Math.ceil(comorbidityTypesArray.length / 2));


    return (
        <div className="w-full max-w-[120rem] mx-auto bg-[#f5f4f4]">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap w-full justify-center pb-4">
                    <div className="w-4/5 md:w-3/5 underline underline-offset-8">
                        <h3 className="text-2xl text-left font-semibold underline underline-offset-8 mt-8 text-[#005077] underline-[#005077]">Step 2: Your Personal Details</h3>
                    </div>
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                    <div className="flex flex-col md:flex-row justify-between w-full gap-2 ">
                        <div className="w-full md:w-[45%] mb-4 md:mb-0">
                            <label htmlFor="firstName" className="text-left font-medium text-base text-gray-800">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                placeholder="Your first name"
                                required
                                value={caregiverData.firstName}
                                onChange={event => {
                                    const inputFN = event.target.value;
                                    const lettersFN = inputFN.replace(/[^a-zA-Z]/g, '');
                                    handleCaregiverChange('firstName', lettersFN);
                                }} />
                        </div>
                        <div className="w-full md:w-[10%] ">
                            <label htmlFor="middleName" className="text-left font-medium text-base text-gray-800">Middle Name</label>
                            <input
                                type="text"
                                id="middleName"
                                name="middleName"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                placeholder=""
                                value={caregiverData.middleName}
                                onChange={event => {
                                    const inputLN = event.target.value;
                                    const lettersLN = inputLN.replace(/[^a-zA-Z]/g, '');
                                    handleCaregiverChange('middleName', lettersLN);
                                }} />
                        </div>
                        <div className="w-full md:w-[45%] ">
                            <label htmlFor="lastName" className="text-left font-medium text-base text-gray-800">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                placeholder="Your last name"
                                required
                                value={caregiverData.lastName}
                                onChange={event => {
                                    const inputLN = event.target.value;
                                    const lettersLN = inputLN.replace(/[^a-zA-Z]/g, '');
                                    handleCaregiverChange('lastName', lettersLN);
                                }} />
                        </div>
                    </div>
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                    <div className="flex flex-col md:flex-row justify-between w-full gap-2 ">
                        <div className="w-full md:w-1/2 mr-2 mb-4 md:mb-0">
                            <label htmlFor="dob" className="w-full text-left font-medium text-base text-gray-800">Date of Birth</label>
                            <input
                                type="date"
                                id="dob"
                                name="dob"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                placeholder="Select Date"
                                required
                                value={caregiverData.dob}
                                onChange={event => handleCaregiverChange('dob', event.target.value)}
                            />
                        </div>
                        <div className="w-full md:w-1/2">
                            <label htmlFor="ethnicity" className="w-full text-left font-medium text-base text-gray-800">Ethnicity</label>
                            <input
                                type="text"
                                id="ethnicity"
                                name="ethnicity"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]" placeholder="Your ethnicity"
                                required
                                value={caregiverData.ethnicity}
                                onChange={event => handleCaregiverChange('ethnicity', event.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                    <div className="flex flex-col md:flex-row justify-between w-full gap-2 ">
                        <div className="w-full md:w-1/2 mr-2 mb-4 md:mb-0">
                            <label htmlFor="sex" className="w-full text-left font-medium text-base text-gray-800">Biological Sex</label>
                            <select
                                id="sex"
                                name="sex"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                required
                                value={caregiverData.sex}
                                onChange={event => handleCaregiverChange('sex', event.target.value)}
                            >
                                <option value="" disabled>Select your sex</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Prefer not to say">Prefer not to say</option>
                            </select>
                        </div>
                        <div className="w-full md:w-1/2">
                            <label htmlFor="gender" className="w-full text-left font-medium text-base text-gray-800">Gender Identity</label>
                            <input
                                type="text"
                                id="gender"
                                name="gender"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]" placeholder="Your gender"
                                required
                                value={caregiverData.gender}
                                onChange={event => handleCaregiverChange('gender', event.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                    <div className="flex flex-col md:flex-row justify-between w-full gap-2 ">
                        <div className="w-full md:w-1/2 mr-2 mb-4 md:mb-0">
                            <label htmlFor="email" className="w-full text-left font-medium text-base text-gray-800">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]" placeholder="Your email"
                                required
                                value={caregiverData.email}
                                onChange={event => handleCaregiverChange('email', event.target.value)}
                            />
                        </div>
                        <div className="w-full md:w-1/2">
                            <label htmlFor="number" className="w-full text-left font-medium text-base text-gray-800">Phone number</label>
                            <PhoneInput
                                country={'us'}
                                value={caregiverData.phoneNumber}
                                onChange={event => handleCaregiverChange('phoneNumber', event.toString())}
                                required
                                inputProps={{
                                    name: 'number',
                                    required: true,
                                    placeholder: 'Your phone number',
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                    <div className="flex flex-col md:flex-row justify-between w-full gap-2 ">
                        <div className="w-full md:w-1/2 mr-2 mb-4 md:mb-0">
                            <label htmlFor="password" className="w-full text-left font-medium text-base text-gray-800">Password</label>
                            <div className="relative w-full">
                                <input
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                    placeholder="Password"
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    required
                                />
                                <button onClick={togglePasswordVisibility} className="absolute right-2 top-1/2 transform -translate-y-1/3">
                                    <img src={isPasswordVisible ? NoVis : Vis} alt="Visibility Toggle" className="w-6 h-6" />
                                </button>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2">
                            <label htmlFor="confirmPass" className="w-full text-left font-medium text-base text-gray-800">Confirm Password</label>
                            <div className="relative w-full">
                                <input
                                    type={isConfirmPassVisible ? 'text' : 'password'}
                                    id="confirmPass"
                                    name="confirmPass"
                                    className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={event => setConfirmPassword(event.target.value)}
                                    required
                                />
                                <button onClick={toggleConfirmPassVisibility} className="absolute right-2 top-1/2 transform -translate-y-1/3">
                                    <img src={isConfirmPassVisible ? NoVis : Vis} alt="Visibility Toggle" className="w-6 h-6" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 items-center">
                    <label htmlFor="streetAddress1" className="w-full text-left font-medium text-base text-gray-800">Street Address 1</label>
                    <input
                        type="text"
                        id="streetAddress1"
                        name="streetAddress1"
                        className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="Your street address (Line 1)"
                        required
                        value={caregiverData.streetAddress1}
                        onChange={event => handleCaregiverChange('streetAddress1', event.target.value)}
                    />
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 items-center">
                    <label htmlFor="streetAddress2" className="w-full text-left font-medium text-base text-gray-800 ">Street Address 2</label>
                    <input
                        type="text"
                        id="streetAddress2"
                        name="streetAddress2"
                        className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="Your street address (Line 2 OPTIONAL)"
                        value={caregiverData.streetAddress2}
                        onChange={event => handleCaregiverChange('streetAddress2', event.target.value)}
                    />
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 ">
                    <div className="flex flex-col md:flex-row justify-between w-full gap-2 ">
                        <div className="w-full md:w-[40%] mb-4 md:mb-0">
                            <label htmlFor="city" className="text-left font-medium text-base text-gray-800">City</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                placeholder="Your City"
                                required
                                value={caregiverData.city}
                                onChange={event => handleCaregiverChange('city', event.target.value)}
                            />
                        </div>
                        <div className="w-full md:w-[20%] mb-4 md:mb-0">
                            <label htmlFor="state" className="text-left font-medium text-base text-gray-800">State</label>
                            <input
                                type="state"
                                id="state"
                                name="state"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                placeholder="Your State"
                                required
                                value={caregiverData.state}
                                onChange={event => handleCaregiverChange('state', event.target.value)}
                            />
                        </div>

                        <div className="w-full md:w-1/2 mr-2 md:mb-0">
                            <label htmlFor="postalCode" className="text-left font-medium text-base text-gray-800">Postal Code</label>
                            <input
                                type="number"
                                id="postalCode"
                                name="postalCode"
                                className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                                placeholder="Your Postal Code"
                                required
                                value={caregiverData.postalCode}
                                onChange={event => {
                                    const value = event.target.value.slice(0, 5);
                                    handleCaregiverChange('postalCode', value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 items-center">
                    <label htmlFor="country" className="w-full text-left font-medium text-base text-gray-800">Country</label>
                    <input
                        type="text"
                        id="country"
                        name="country"
                        className="h-12 block py-2.5 px-4 w-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]"
                        placeholder="Your country"
                        required
                        value={caregiverData.country}
                        onChange={event => handleCaregiverChange('country', event.target.value)}
                    />
                </div>

                <div className="flex flex-wrap w-full justify-center pb-4">
                    <div className="w-4/5 md:w-3/5 underline underline-offset-8">
                        <h3 className="text-2xl text-left font-semibold underline underline-offset-8 mt-8 text-[#005077] ">Step 3: Your Interests </h3>
                    </div>
                </div>
                <div className="w-4/5 md:w-3/5 h-[16rem] mx-auto flex flex-col pt-4">
                    <label htmlFor="interests" className="w-full text-left font-medium text-base text-gray-800">Please describe any clinical trials that you are interested in</label>
                    <textarea
                        type="text"
                        id="interests"
                        name="interests"
                        className="h-12 block py-2.5 px-4 w-full h-full text-base text-gray-900 border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#005077]" placeholder=""
                        value={caregiverData.interests}
                        onChange={event => handleCaregiverChange('interests', event.target.value)}
                    />
                </div>

                <div className="flex flex-wrap w-full justify-center">
                    <div className="w-4/5 md:w-3/5">
                        <h3 className="text-left font-medium text-base text-gray-800 pt-8 ">Please tell us a bit more about yourself </h3>
                    </div>
                </div>

                {comorbidityTypesArray.map(type => (
                    <div className="w-4/5 md:w-3/5 pl-6 mx-auto flex flex-col mt-2 border-2 border-gray-300 p-2  shadow-lg" id={comorbidityTypesStringReverse[type]}>
                        <label className="font-normal text-lg text-gray-800">
                            {type}
                        </label>
                        <div className="grid grid-cols-2 2xl:grid-cols-3">
                            {comorbidityTypesOptions[comorbidityTypesStringReverse[type]].map(option => (
                                <div className='gap-2' id={comorbidityTypesStringReverse[type] + '-' + option}>
                                    <label className="font-normal text-base text-gray-800 flex items-center gap-2  "><input type="checkbox" name={comorbidityTypesStringReverse[type]} value={option} onChange={event => handleComorbidities(event.target.name, event.target.value, event.target.checked,)} /> {option}</label>
                                </div>
                            )
                            )}
                        </div>
                    </div>
                ))
                }

                <div className="flex flex-wrap w-full justify-center pb-4">

                    <h3 className="w-4/5 md:w-3/5 underline underline-offset-8 underline-[#005077] font-semibold text-left text-2xl pt-8 text-[#005077] ">Step 4: Consent</h3>

                </div>

                <div className="w-4/5 md:w-3/5 mx-auto">
                    <h3 className="text-lg">Please read this consent form before continuing</h3>
                    <div className="flex flex-col mt-4 h-80 border-2 overflow-auto p-4">
                        <h3 className="">The BRAIN Foundation’s goal is to catalyze scientific research that can improve the quality of life for those with neurological disorders and developmental disabilities through safe and evidence-based medicine. Mass participation in clinical trials is vital in achieving this. The BRAIN Foundation operates the Brain Registry as a tool to facilitate informed participation in clinical trials for individuals with neurological disorders and developmental disabilities. By accessing and utilizing the Brain Registry, users acknowledge and agree to the following terms and conditions:</h3>
                        <br />

                        <div className="ml-8 ">
                            <h1 className="font-semibold">Information Collection and Consent:</h1>
                            <h3 className="">The Brain Registry requests personal information from users for the purpose of notifying them of relevant clinical trials. Participation in the Brain Registry is voluntary, and users retain full control over the information they choose to provide. Submission of information does not constitute enrollment in clinical trials.
                            </h3>
                        </div>
                        <br />
                        <div className="ml-8 ">
                            <h1 className="font-semibold">Data Handling and Privacy:</h1>
                            <h3 className="">The Foundation is committed to maintaining the confidentiality and security of user data. All information collected through the Brain Registry is stored in HIPAA-compliant databases and handled in accordance with applicable privacy laws and regulations. Personal information and login credentials are stored separately for greater security.

                            </h3>
                        </div>
                        <br />
                        <div className="ml-8 ">
                            <h1 className="font-semibold">Purpose and Use of Information: </h1>
                            <h3 className="">The primary purpose of the Brain Registry is to connect prospective participants with clinical trials that may be of interest to them. With the user's consent, the Foundation may share their information with clinical trial sponsors for this purpose. Users understand and agree that the Foundation is not responsible for the conduct or outcomes of any clinical trials in which they may choose to participate.
                            </h3>
                        </div>
                        <br />
                        <div className="ml-8 ">
                            <h1 className="font-semibold">Limitation of Liability:</h1>
                            <h3 className=""> The Brain Registry is only a tool for connecting prospective participants with clinical trials. By using the Brain Registry, users agree to release the Brain Foundation from any liability.

                            </h3>
                        </div>
                        <br />
                        <div className="ml-8 ">
                            <h1 className="font-semibold">Modification of Terms:</h1>
                            <h3 className="">The Foundation reserves the right to modify, suspend, or terminate the Brain Registry or its terms of use at any time without prior notice. Users are encouraged to review the terms periodically for updates or changes.
                            </h3>
                        </div>
                        <br />
                        <h3 className="">By creating an account with the Brain Registry, users indicate their understanding of and agreement to abide by these terms and conditions. If you do not agree with any provision of this legal notice, you are still encouraged to use the Brain Registry as a search tool.</h3>
                    </div>
                    <div className="mt-4 mb-4 flex items-start">
                        <input
                            type="checkbox"
                            name="consent1"
                            checked={consents}
                            onChange={handleCheckboxChange}
                            className="form-checkbox flex h-6 w-6 min-w-6 mr-2"
                        />
                        <label className="h-fit block font-medium flex-1 text-base text-gray-800">
                            I have read and understood the consent form. I want to continue creating an account on the Brain Registry
                        </label>
                    </div>
                </div>

                <div className="w-4/5 md:w-3/5 mx-auto flex flex-col pt-4 pb-20 items-end">
                    <button
                        type="submit"
                        className="w-[5rem] bg-[#FFA400] text-white py-2 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-[#005077] focus:ring-opacity-50 rounded-full">
                        Submit
                    </button>
                </div>

            </form>
        </div>
    );
};

export default Registry;